import React from "react";
import {
  Paper,
  Typography,
  Box,
  Stack,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Chip,
  SpeedDial,
  SpeedDialIcon,
  SpeedDialAction,
  TextField,
  InputAdornment,
  Fab,
} from "@mui/material";
import { Routes, Route, Link, useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import RefreshIcon from "@mui/icons-material/Refresh";
import DownloadIcon from "@mui/icons-material/Download";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import MicIcon from "@mui/icons-material/Mic";
import AssignmentIcon from "@mui/icons-material/Assignment";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import CancelIcon from "@mui/icons-material/Cancel";
import SettingsIcon from "@mui/icons-material/Settings";
import SettingsTwoToneIcon from "@mui/icons-material/SettingsTwoTone";
import DataCount from "../components/DataCount";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import moment from "moment";
import WaveSurfer from "wavesurfer.js";
import { blue } from "@mui/material/colors";
import { Fade } from "react-awesome-reveal";
import { api, audioPath } from "../resources/config";
import { TTaskUnit } from "../resources/types";
import CurtainWithProgress from "../components/CurtainWithProgress";
import TaskUnitCard from "../components/TaskUnitCard";
import { useConfirmation } from "../services/ConfirmationService";
import { useSnackbar } from "../services/CustomSnackbarService";
import TaskUnitEditor from "../components/TaskUnitEditor";
import { matchKeywords } from "../libraries/helper";
import ResponsiveAppBar from "../components/AppBar";
import MenuBreadCrumbs from "../components/MenuBreadCrumbs";
import ProjectInUseSelector from "../components/ProjectInUseSelector";
import { TSignedInUserWithSetUser } from "../resources/types";
import { SignedInUserContext } from "../contexts/SignedInUserContext";
import SimpleJsonViewer from "../components/SimpleJsonViewer";
import ProjectChanger from "../components/ProjectChanger";
import ErrorDisplay from "../components/ErrorDisplay";
import ItemTitleInEdit from "../components/ItemTitleInEdit";

function TaskUnitPage() {
  const signedInUserWithSetUser: TSignedInUserWithSetUser | null =
    React.useContext(SignedInUserContext);
  const [taskUnitForEdit, setTaskUnitForEdit] =
    React.useState<TTaskUnit | null>(null);
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [openSpeedDial, setOpenSpeedDial] = React.useState(false);
  const [createNew, setCreateNew] = React.useState(false);
  const [taskUnitFilter, setTaskUnitFilter] = React.useState("");
  const [taskDetailsFilter, setTaskDetailsFilter] = React.useState("");
  const navigate = useNavigate();
  const confirm = useConfirmation();
  const snackbar = useSnackbar();
  const [isProjectChangeOpen, setIsProjectChangeOpen] = React.useState(false);
  const [taskUnitCodeForProjectChange, setTaskUnitCodeForProjectChange] =
    React.useState("");
  const [originalProjectIds, setOriginalProjectIds] = React.useState<string[]>(
    []
  );

  const projectId = React.useMemo(
    () =>
      signedInUserWithSetUser?.projects?.find(
        (v) => v.code === signedInUserWithSetUser?.projectCodeInUse
      )?._id,
    [
      signedInUserWithSetUser?.projectCodeInUse,
      signedInUserWithSetUser?.projects,
    ]
  );

  const {
    isLoading,
    isFetching,
    isError,
    error,
    data: taskUnitData,
    refetch: refetchTaskUnitData,
  } = useQuery(
    ["taskunit-data", projectId],
    () =>
      fetch(api.getTaskUnits({ projectId }), {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: `Bearer ${signedInUserWithSetUser?.user?.token}`,
        },
      }).then((res) => {
        console.log(`status = ${res.status}`);
        if (res.status === 401) {
          throw new Error("Unauthorized");
        }
        return res.json();
      }),
    {
      enabled: !!signedInUserWithSetUser?.user?.username && !!projectId,
    }
  );

  React.useEffect(() => {
    if (isError) {
      console.log(`isError: ${error}`);
      if ((error as Error).message === "Unauthorized") {
        signedInUserWithSetUser?.setUser(null);
      }
    }
  }, [isError, error, signedInUserWithSetUser]);

  const handleEdit = (taskCode: string) => {
    console.log(`handleEdit() taskCode=${taskCode}`);
    const tu = taskUnitData.find(
      (p: TTaskUnit) => p.code === taskCode
    ) as TTaskUnit;
    if (!tu) {
      setTaskUnitForEdit(null);
      return;
    }
    const cloned = {
      ...tu,
      details: {
        ...tu.details,
      },
    } as TTaskUnit;
    setCreateNew(false);
    setTaskUnitForEdit(cloned);
  };

  const handleCopy = (taskCode: string) => {
    console.log(`handleCopy() taskCode=${taskCode}`);
    const tu = taskUnitData.find(
      (p: TTaskUnit) => p.code === taskCode
    ) as TTaskUnit;
    if (!tu) {
      setTaskUnitForEdit(null);
      return;
    }
    const cloned = {
      ...tu,
      code: "",
      details: {
        ...tu.details,
      },
    } as TTaskUnit;
    setCreateNew(true);
    setTaskUnitForEdit(cloned);
  };

  const handleDelete = (taskCode: string) => {
    if (!taskCode) {
      return;
    }
    console.log(`handleDelete() taskCode=${taskCode}`);
    confirm({
      variant: "danger",
      title: `Delete task unit "${taskCode}"`,
      description: `Are you sure to delete task unit "${taskCode}"?`,
      cancelButtonText: "No",
      agreeButtonText: "Yes, go ahead to delete",
      catchOnCancel: true,
    })
      .then(() => {
        setIsProcessing(true);
        console.log(`Go ahead to delete ${taskCode}`);
        fetch(api.deleteTaskUnit({ projectId, taskCode }), {
          method: "DELETE",
          credentials: "include",
          headers: {
            // "Content-Type": "application/json;charset=UTF-8",
            Authorization: `Bearer ${signedInUserWithSetUser?.user?.token}`,
          },
        })
          .then((res) => res.json())
          .then((json) => {
            setIsProcessing(false);
            snackbar({
              severity: "success",
              message: `Task unit "${taskCode}" deleted successfully`,
            });
            refetchTaskUnitData();
          })
          .catch((error) => {
            setIsProcessing(false);
            confirm({
              variant: "error",
              title: `Error deleting task unit "${taskCode}"`,
              description: `Deletion of task unit "${taskCode}" failed: ${(
                error as Error
              )?.toString()}`,
            });
          });
      })
      .catch(() => {
        setIsProcessing(false);
        snackbar({
          severity: "info",
          message: `Deletion of task unit "${taskCode}" cancelled`,
        });
      });
  };

  const handleTaskUnitChange = (taskUnit: TTaskUnit) => {
    console.log(
      `handleTaskUnitChange(), taskUnit = ${JSON.stringify(taskUnit)}`
    );
    setTaskUnitForEdit(taskUnit);
  };

  const handleSaveEdited = async () => {
    console.log(`handleSaveEdited`);
    if (!taskUnitForEdit) {
      return;
    }
    setIsProcessing(true);
    fetch(
      createNew
        ? api.createTaskUnit({ projectId })
        : api.updateTaskUnit({ projectId }),
      {
        method: createNew ? "PUT" : "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: `Bearer ${signedInUserWithSetUser?.user?.token}`,
        },
        body: JSON.stringify(taskUnitForEdit),
      }
    )
      .then((res) => res.json())
      .then((json) => {
        console.log(json);
        if (!createNew) {
          if (!json || !json[0] || json[0].code !== taskUnitForEdit.code) {
            throw new Error(
              `Failed to save task unit "${taskUnitForEdit.code}" ${
                json.message ?? ""
              }`
            );
          } else {
            setIsProcessing(false);
            snackbar({
              severity: "success",
              message: `Saving task unit "${taskUnitForEdit.code}" successfully`,
            });
            // setTaskUnitForEdit(json[0] as TTaskUnit)
            setTaskUnitForEdit(null);
          }
        } else {
          if (!json || json.code !== taskUnitForEdit.code) {
            throw new Error(
              `Failed to create task unit "${taskUnitForEdit.code}" ${
                json.message ?? ""
              }`
            );
          } else {
            setIsProcessing(false);
            snackbar({
              severity: "success",
              message: `Creating new task unit "${taskUnitForEdit.code}" successfully`,
            });
            setTaskUnitForEdit(null);
          }
        }
        refetchTaskUnitData();
      })
      .catch((err) => {
        setIsProcessing(false);
        confirm({
          variant: "error",
          title: `Error saving task unit "${taskUnitForEdit.code}"`,
          description:
            err?.toString() ??
            "Error saving task unit. Please try again later.",
          agreeButtonText: "OK",
        });
      });
  };

  const handleCancel = () => {
    confirm({
      variant: "danger",
      title: `Cancel task unit editing`,
      description: `Are you sure to cancel editing this task unit?`,
      cancelButtonText: "No, stay here",
      agreeButtonText: "Yes, please cancel",
    }).then(() => {
      setTaskUnitForEdit(null);
    });
  };

  const handleCreateTaskUnit = () => {
    console.log(`handleCreateTaskUnit`);
    setCreateNew(true);
    setTaskUnitForEdit({
      code: "",
      language: "",
      mode: "",
      details: {},
      description: "",
      createdDate: new Date(),
      modifiedDate: new Date(),
    });
  };

  const handleTaskUnitFilterChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setTaskUnitFilter(event.target.value);
  };

  const handleTaskUnitFilterClear = () => {
    setTaskUnitFilter("");
  };

  const handleTaskDetailsFilterChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setTaskDetailsFilter(event.target.value);
  };

  const handleTaskDetailsFilterClear = () => {
    setTaskDetailsFilter("");
  };

  const filteredSortedTaskUnits = React.useMemo(() => {
    const taskUnitFilterKeywords = taskUnitFilter.trim().split(/\s+/);
    const taskFilterKeywords = taskDetailsFilter.trim().split(/\s+/);

    const filteredTaskUnits = taskUnitData?.filter(
      (v: TTaskUnit) =>
        !taskUnitFilter ||
        matchKeywords(
          [v.code, v.description, v.language],
          taskUnitFilterKeywords
        )
    );

    const filteredByTask = filteredTaskUnits?.filter(
      (v: TTaskUnit) =>
        !taskDetailsFilter ||
        matchKeywords([JSON.stringify(v.details)], taskFilterKeywords)
    );

    return filteredByTask?.sort(
      (a: TTaskUnit, b: TTaskUnit) =>
        moment(b.modifiedDate).valueOf() - moment(a.modifiedDate).valueOf()
    );
  }, [taskUnitData, taskUnitFilter, taskDetailsFilter]);

  const handleProjectChange = React.useCallback(
    (code: string) => {
      const tu = taskUnitData.find(
        (p: TTaskUnit) => p.code === code
      ) as TTaskUnit;
      if (!tu) {
        return;
      }
      setTaskUnitCodeForProjectChange(code);
      setOriginalProjectIds(tu.project ?? []);
      setIsProjectChangeOpen(true);
    },
    [taskUnitData]
  );

  const handleProjectChangeConfirm = async (projectCodes: string[]) => {
    console.log(`handleProjectChangeConfirm: ${JSON.stringify(projectCodes)}`);
    try {
      const json = await fetch(api.changeTaskUnitProject({ projectId }), {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${signedInUserWithSetUser?.user?.token}`,
        },
        body: JSON.stringify({
          projectCodes,
          taskUnitCodes: [taskUnitCodeForProjectChange],
        }),
      }).then((res) => res.json());
      console.log(`response`);
      console.log(json);
      if (json.error) {
        throw new Error(json.message || json.error);
      }
      setIsProcessing(false);
      snackbar({
        severity: "success",
        message:
          "Project designation of task unit has been successfully updated.",
      });
      setTaskUnitCodeForProjectChange("");
      setOriginalProjectIds([]);
      setIsProjectChangeOpen(false);
      refetchTaskUnitData();
    } catch (err) {
      setIsProcessing(false);
      confirm({
        variant: "error",
        title: `Error changing project designation for task unit "${taskUnitCodeForProjectChange}"`,
        description:
          (err as Error)?.toString() ??
          "Error updating this task unit. Please check and try again.",
        agreeButtonText: "OK",
      });
    }
  };

  const disabled = React.useMemo(
    () => isLoading || isFetching || isProcessing,
    [isLoading, isFetching, isProcessing]
  );

  const projectFound = React.useMemo(() => {
    let found = false;
    if (
      signedInUserWithSetUser?.projectCodeInUse &&
      signedInUserWithSetUser?.projects &&
      signedInUserWithSetUser?.projects?.length > 0
    ) {
      if (
        signedInUserWithSetUser?.projects.find(
          (p) => p.code === signedInUserWithSetUser?.projectCodeInUse
        )
      ) {
        found = true;
      }
    }
    return found;
  }, [
    signedInUserWithSetUser?.projectCodeInUse,
    signedInUserWithSetUser?.projects,
  ]);

  return (
    <Paper elevation={0} sx={{ height: "100vh", overflow: "scroll" }}>
      <ResponsiveAppBar />
      <MenuBreadCrumbs items={["home", "taskUnit"]} />
      <Stack sx={{ mt: 2 }}>
        <Box sx={{ m: 1, ml: 4, mr: 4 }}>
          <ProjectInUseSelector readOnly={!!taskUnitForEdit} />
        </Box>
        <Box sx={{ mt: 2, ml: 4 }}>
          {taskUnitForEdit ? (
            <ItemTitleInEdit
              prefix={createNew ? `Create new task unit` : `Edit task unit`}
              itemTitle={taskUnitForEdit.code}
            />
          ) : (
            <Typography variant="h3">Task Units</Typography>
          )}
        </Box>
        {isError && (
          <Box sx={{ mt: 4, ml: 4, mr: 4, p: 4 }}>
            <ErrorDisplay error={(error as Error).toString()} />
          </Box>
        )}
        {projectFound &&
          !isError &&
          signedInUserWithSetUser?.accessRight?.canEditTaskUnits &&
          !taskUnitForEdit && (
            <Box
              display={"flex"}
              flexDirection="row"
              justifyContent={"space-between"}
              sx={{ ml: 4 }}
            >
              <Button startIcon={<AddIcon />} onClick={handleCreateTaskUnit}>
                Create a new task unit
              </Button>
            </Box>
          )}
        {!projectFound && (
          <Typography variant="body1" sx={{ ml: 4, fontStyle: "italic" }}>
            A project is required
          </Typography>
        )}
        {!isError &&
          taskUnitData &&
          !taskUnitForEdit &&
          taskUnitData.length === 0 && (
            <Box sx={{ mt: 4, ml: 4, mr: 4 }}>
              <Typography variant="body1">No task units found</Typography>
            </Box>
          )}

        {!isError &&
          taskUnitData &&
          !taskUnitForEdit &&
          taskUnitData.length > 0 && (
            <Box
              display={"flex"}
              flexDirection="column"
              justifyContent={"space-between"}
            >
              {!taskUnitForEdit && (
                <Box
                  display={"flex"}
                  flexDirection="row"
                  justifyContent={"space-between"}
                  sx={{ mt: 2, mb: 2, mr: 2 }}
                >
                  <Box
                    flex="1"
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-end"
                    sx={{ mr: 2, mt: 1 }}
                  >
                    <Typography variant="h4">{` `}</Typography>
                  </Box>
                  <Box
                    flex="8"
                    display="flex"
                    flexDirection="row"
                    justifyContent={"space-between"}
                  >
                    <Typography>{`Task unit (${filteredSortedTaskUnits.length})`}</Typography>
                    <TextField
                      margin="none"
                      size="small"
                      variant="standard"
                      value={taskUnitFilter}
                      onChange={handleTaskUnitFilterChange}
                      placeholder="Filter"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={handleTaskUnitFilterClear}>
                              <ClearIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                  <Box
                    flex="7"
                    sx={{ ml: 2, mr: 0 }}
                    display="flex"
                    flexDirection="row"
                    justifyContent={"space-between"}
                  >
                    <Typography>Task details</Typography>
                    <TextField
                      margin="none"
                      size="small"
                      variant="standard"
                      value={taskDetailsFilter}
                      onChange={handleTaskDetailsFilterChange}
                      placeholder="Filter"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={handleTaskDetailsFilterClear}>
                              <ClearIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                </Box>
              )}
              {!taskUnitForEdit &&
                filteredSortedTaskUnits?.map((p: TTaskUnit, pIndex: number) => (
                  <Box
                    key={p.code}
                    display={"flex"}
                    flexDirection="row"
                    justifyContent={"space-between"}
                    sx={{ mb: 2, mr: 2 }}
                  >
                    <Box
                      flex="1"
                      display="flex"
                      flexDirection="column"
                      alignItems="flex-end"
                      sx={{ mr: 2, mt: 1 }}
                    >
                      <Typography variant="h4">{`${pIndex + 1}.`}</Typography>
                    </Box>
                    <Box flex="8">
                      <TaskUnitCard
                        taskUnit={p as TTaskUnit}
                        onEdit={() => handleEdit(p.code)}
                        onCopy={() => handleCopy(p.code)}
                        onDelete={() => handleDelete(p.code)}
                        canEditTaskUnits={
                          !!signedInUserWithSetUser?.accessRight
                            ?.canEditTaskUnits
                        }
                        canDeleteTaskUnits={
                          !!signedInUserWithSetUser?.accessRight
                            ?.canDeleteTaskUnits
                        }
                        {...(!!signedInUserWithSetUser?.accessRight
                          ?.canManageAllProjects && {
                          onProjectChange: () => handleProjectChange(p.code),
                        })}
                      />
                    </Box>
                    <Box flex="7" flexWrap={"wrap"} sx={{ ml: 2 }}>
                      <SimpleJsonViewer
                        json={JSON.stringify(p.details, null, 2)}
                      />
                      {/*<pre style={{ whiteSpace: 'break-spaces' }}>{JSON.stringify(p.details, null, 2).split('\n').slice(0, 5).join('\n') + '...'}</pre>*/}
                      {/*p.taskUnits.map((t, index) => {
                  if (typeof t === 'string') {
                    return <Chip key={t} label={t} sx={{ mr: 1, mb: 1, borderRadius: 2 }} />
                  } else {
                    return <Chip key={t.code ?? index} label={t.code ?? '-'} sx={{ mr: 1, mb: 1 }} />
                  }
                })*/}
                    </Box>
                  </Box>
                ))}
            </Box>
          )}
        {!isError && taskUnitData && taskUnitForEdit && (
          <Box
            display={"flex"}
            flexDirection="column"
            justifyContent={"space-between"}
            sx={{ mr: 24, mb: 12 }}
          >
            <Box sx={{ ml: 4 }}>
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                mb={4}
              >
                <Button startIcon={<ArrowBackIosIcon />} onClick={handleCancel}>
                  Back to task unit list
                </Button>
              </Box>
              <TaskUnitEditor
                disabled={disabled}
                createNew={createNew}
                taskUnit={taskUnitForEdit}
                onChange={handleTaskUnitChange}
              />
            </Box>
          </Box>
        )}
        {disabled && !error && <CurtainWithProgress open={disabled} />}
        {!!taskUnitForEdit && (
          <React.Fragment>
            <Fab
              color="warning"
              aria-label="cancel"
              variant="extended"
              onClick={handleCancel}
              sx={{ position: "fixed", bottom: 96, right: 32 }}
            >
              <CancelIcon sx={{ mr: 1 }} />
              Cancel
            </Fab>
            <Fab
              color="primary"
              aria-label="save"
              variant="extended"
              onClick={handleSaveEdited}
              sx={{ position: "fixed", bottom: 32, right: 32 }}
            >
              <SaveIcon sx={{ mr: 1 }} />
              Save
            </Fab>
          </React.Fragment>
        )}
      </Stack>
      <ProjectChanger
        show={isProjectChangeOpen}
        onClose={() => setIsProjectChangeOpen(false)}
        onCancel={() => setIsProjectChangeOpen(false)}
        selectedProjectIds={originalProjectIds}
        title={`Change project designation for ${taskUnitCodeForProjectChange}`}
        helperText={
          "Designate the projects that will have access to this task unit"
        }
        onConfirm={handleProjectChangeConfirm}
      />
    </Paper>
  );
}

export default TaskUnitPage;
