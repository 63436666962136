import React from 'react'
import { Snackbar } from '@mui/material';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


export interface CustomSnackbarOptions {
  severity?: 'success' | 'error' | 'info' | 'warning';
  message?: string;
}

interface CustomSnackbarProps extends CustomSnackbarOptions {
  open: boolean;
  onClose: () => void;
  onExited: () => void;
}

export const CustomSnackBar: React.FC<CustomSnackbarProps> = ({
  open,
  severity = 'info',
  message = '',
  onClose,
  onExited
}) => {
  const handleClose = (event?: Event | React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    onClose()
  };

  return (
    <Snackbar open={open} autoHideDuration={4000} onClose={handleClose}>
      <Alert elevation={6} variant="filled" onClose={handleClose} severity={severity}>
        {message}
      </Alert>
    </Snackbar>
  )
}

export default CustomSnackBar