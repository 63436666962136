import React from 'react'
import { api } from "../resources/config"
import { useQuery } from 'react-query'
import { Autocomplete, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField, Button, DialogContentText } from '@mui/material'
import ProjectSelector from './ProjectSelector'
import { TProject, TUserProject } from "../resources/types"
import { TSignedInUserWithSetUser } from '../resources/types'
import { SignedInUserContext } from '../contexts/SignedInUserContext';

const ProjectChanger = ({
  show, selectedProjectIds, title, helperText, onConfirm, onClose, onCancel
}: {
  show: boolean;
  selectedProjectIds: string[];
  onClose: () => void;
  onCancel: () => void;
  title: string;
  helperText: string;
  onConfirm: (projectCodes: string[]) => void;
}) => {
  const signedInUserWithSetUser: TSignedInUserWithSetUser | null = React.useContext(SignedInUserContext)
  const [projectData, setProjectData] = React.useState<TUserProject[]>([]);
  const [selectedProjectData, setSelectedProjectData] = React.useState<TUserProject[]>([])
  // const [open, setOpen] = React.useState(false)

  React.useEffect(
    () => {
      setSelectedProjectData(selectedProjectIds.map(p => projectData.find((v: TUserProject) => v._id === p)) as TUserProject[])
    },
    [selectedProjectIds, projectData]
  )

  const { isLoading, isFetching, error, data: rawProjectData, refetch: refetchProjectData } = useQuery(
    ['project-data'],
    () =>
      fetch(api.getProjects({}), {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Authorization": `Bearer ${signedInUserWithSetUser?.user?.token}`
        }
      })
        .then((res) => res.json()),
    {
      enabled: !!signedInUserWithSetUser?.accessRight?.canManageAllProjects,
    }
  );

  React.useEffect(
    () => {
      if (rawProjectData && rawProjectData.docs instanceof Array) {
        setProjectData([
          ...(rawProjectData.docs as TProject[])
        ])
      }
    },
    [rawProjectData]
  )

  // const handleOpen = () => {
  //   setOpen(true)
  // }

  // const handleClose = () => {
  //   setOpen(false)
  // }

  // const handleChange = (event: React.ChangeEvent<{}>, value: TUserProject[]) => {
  //   console.log(value)
  //   onConfirm && onConfirm(value.map(v => v.code))
  // }

  const handleProjectChange = (projects: TUserProject[]) => {
    console.log(`handleProjectChange: ${JSON.stringify(projects)}`)
    setSelectedProjectData(projects)
  }

  const handleConfirm = () => {
    onConfirm(selectedProjectData.map(v => v.code))
  }

  return (
    <Dialog fullWidth maxWidth="md" onClose={onClose} open={show}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Stack sx={{ m: 1, p: 1 }}>
          <ProjectSelector
            selectedProjects={selectedProjectData}
            onChange={handleProjectChange}
            helperText={helperText}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} sx={{mr: 2}}>
          Cancel
        </Button>
        <Button onClick={handleConfirm}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ProjectChanger