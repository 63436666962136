import React from "react";
import { Paper, Button, Typography, Box, Divider, TextField, InputAdornment, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, List, ListItem, ListItemAvatar, ListItemText, Avatar } from '@mui/material'


const SurveyResponse = ({ response }: {
  response: {
    [id: string]: string | number
  }
}) => {
  return (
    <Box width="100%">
      <Box display="flex" flexDirection="row" sx={{ width: '100%', pl: 2, borderBottom: 2, borderBottomStyle: 'solid', borderBottomColor: 'lightgrey' }}>
        <Typography variant="h6" sx={{ flex: 5 }}>Question</Typography>
        <Divider orientation="vertical" variant="middle" flexItem sx={{ ml: 4, mr: 4 }} />
        <Typography variant="h6" sx={{ flex: 3 }}>Response</Typography>
      </Box>
      {Object.keys(response).map((v, index) => <Box key={v} display="flex" flexDirection="row" sx={{ width: '100%', pl: 2, pt: 2, pb: 2, backgroundColor: index % 2 ? 'lightgrey' : 'none' }}>
        <Typography variant="body1" sx={{ flex: 5 }}>{v}</Typography>
        <Divider orientation="vertical" variant="middle" flexItem sx={{ ml: 4, mr: 4 }} />
        <Typography variant="body1" sx={{ flex: 3 }}>{response[v]}</Typography>
      </Box>)}
    </Box>
  )
}

export default SurveyResponse