import React from "react";
// import logo from "./logo.svg";
// import "./App.css";
// import { ExperimentWindow } from "jspsych-react";
import {
  useNavigate,
  useSearchParams,
  createSearchParams,
  Link as RouterLink,
} from "react-router-dom";
import { useQuery } from "react-query";
import { Stack } from "@mui/material";
import { initJsPsych } from "jspsych";
import jsPsychHtmlKeyboardResponse from "@jspsych/plugin-html-keyboard-response";
import jsPsychImageKeyboardResponse from "@jspsych/plugin-image-keyboard-response";
import jsPsychAudioKeyboardResponse from "@jspsych/plugin-audio-keyboard-response";
import jsPsychAudioButtonResponse from "@jspsych/plugin-audio-button-response";
import jsPsychInitializeMicrophone from "@jspsych/plugin-initialize-microphone";
import jsPsychHtmlAudioResponse from "@jspsych/plugin-html-audio-response";
import jsPsychCallFunction from "@jspsych/plugin-call-function";
import jsPsychVideoButtonResponse from "@jspsych/plugin-video-button-response";
import jsPsychInstructions from "@jspsych/plugin-instructions";
import jsPsychPreload from "@jspsych/plugin-preload";
import { Roll } from "react-awesome-reveal";
import MailIcon from "@mui/icons-material/Mail";
import ClearIcon from "@mui/icons-material/Clear";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import RateReviewIcon from "@mui/icons-material/RateReview";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import { blue, orange } from "@mui/material/colors";
import { ParticipantContext } from "../contexts/ParticipantContext";
import { TParticipantWithRefetch } from "../resources/types";
import TaskProgressList from "../components/TaskProgressList";
import { api, tasks, projectUrl } from "../resources/config";
import EdUHKLogo from "../assets/images/EdUHK_Signature_RGB.png";
import DoldImage from "../assets/images/dold.gif";
import LmlLogo from "../assets/images/lml-logo.png";
import CrllsLogo from "../assets/images/crlls-logo.png";
import { renderTerm } from "../resources/lexicon";

import {
  Paper,
  AppBar,
  Toolbar,
  Box,
  Button,
  IconButton,
  Link,
  TextField,
  Typography,
  CircularProgress,
  InputAdornment,
} from "@mui/material";

function MaintenancePage() {

  return (
    <Box
      className="App"
      sx={{
        display: "flex",
        flexDirection: { xs: "column" },
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Stack
        flexDirection="row"
        alignItems="center"
        sx={{ mb: 8 }}
        justifyContent="space-around"
      >
        <Box sx={{ m: 2 }}>
          <img src={EdUHKLogo} alt="EdUHK" height={120} />
        </Box>
        <Box sx={{ m: 2 }}>
          <img src={CrllsLogo} alt="CRLLS" height={64} />
        </Box>
        <Box sx={{ m: 2 }}>
          <img src={LmlLogo} alt="LML" height={64} />
        </Box>
      </Stack>
      <Box sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}>
        <img src={DoldImage} alt="DOLD" width={"60%"} />
        <Typography variant="h1">Under maintenance</Typography>
        <Typography variant="h6">Until HKT 6pm 15 Dec 2023</Typography>
      </Box>
    </Box>
  );
}

export default MaintenancePage;
