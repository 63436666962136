import React from "react";
import {
  Paper,
  Typography,
  Box,
  IconButton,
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Breadcrumbs,
  Link
} from "@mui/material";
// import HomeIcon from '@mui/icons-material/Home';
// import WhatshotIcon from '@mui/icons-material/Whatshot';
// import GrainIcon from '@mui/icons-material/Grain';
import HomeIcon from '@mui/icons-material/Home';
import MovieIcon from '@mui/icons-material/Movie';
import DashboardIcon from '@mui/icons-material/Dashboard';
import InboxIcon from '@mui/icons-material/Inbox';
import AllInboxIcon from '@mui/icons-material/AllInbox';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import MicIcon from '@mui/icons-material/Mic';
import NotesIcon from '@mui/icons-material/Notes';
import AssignmentIcon from '@mui/icons-material/Assignment';
import InfoIcon from '@mui/icons-material/Info';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import PeopleIcon from '@mui/icons-material/People';
import GroupsIcon from '@mui/icons-material/Groups';
import HowToRegIcon from '@mui/icons-material/HowToReg';

const menuHierarchy: {
  [key: string]: {
    title: string;
    path: string;
    icon: React.ReactNode;
  }
} = {
  home: {
    title: "Home",
    path: "#/home",
    icon: <HomeIcon />
  },
  dashboard: {
    title: "Dashboard",
    path: "#/dashboard",
    icon: <DashboardIcon />
  },
  media: {
    title: "Media",
    path: "#/media",
    icon: <MovieIcon />
  },
  project: {
    title: "Projects",
    path: "#/project",
    icon: <AccountTreeIcon />
  },
  user: {
    title: "Users",
    path: "#/user",
    icon: <GroupsIcon />
  },
  taskUnit: {
    title: "Task Units",
    path: "#/taskunit",
    icon: <InboxIcon />
  },
  experiment: {
    title: "Experiments",
    path: "#/experiment",
    icon: <AllInboxIcon />
  },
  audiodata: {
    title: "Audio Data",
    path: "#/audiodata",
    icon: <MicIcon />
  },
  textdata: {
    title: "Text Data",
    path: "#/textdata",
    icon: <NotesIcon />
  },
  surveydata: {
    title: "Survey Data",
    path: "#/surveydata",
    icon: <AssignmentIcon />
  },
  aboutus: {
    title: "About Us",
    path: "#/aboutus",
    icon: <InfoIcon />
  },
  learnmore: {
    title: "Learn more",
    path: "#/learn-more",
    icon: <HistoryEduIcon />
  },
  documentation: {
    title: "Documentation",
    path: "#/documentation",
    icon: <LibraryBooksIcon />
  },
  contactus: {
    title: "Contact Us",
    path: "#/contactus",
    icon: <ContactSupportIcon />
  },
  signup: {
    title: "Sign Up",
    path: "#/signup",
    icon: <HowToRegIcon />
  }
}

const MenuBreadCrumbs = ({ items = [] }: { items: string[] }) => {
  return (
    <Breadcrumbs sx={{ mt: 4, ml: 4 }}>
      {
        items.slice(0, items.length - 1).map((item: string) => {
          const { title, path, icon } = menuHierarchy[item] ?? {}
          return (
            <Link
              key={title}
              underline="hover"
              sx={{ display: "flex", alignItems: "center" }}
              color="inherit"
              href={path}
            >
              <Box sx={{ mr: 1 }}>{icon}</Box>
              {title}
            </Link>
          )
        })
      }
      <Typography
        component="span"
        sx={{ display: "flex", alignItems: "center" }}
        color="text.primary"
      >
        <Box sx={{ mr: 1 }}>{menuHierarchy[items[items.length - 1]]?.icon}</Box>
        {menuHierarchy[items[items.length - 1]]?.title}
      </Typography>

    </Breadcrumbs>
  );
};

export default MenuBreadCrumbs;
