import React from "react"
import { Roll } from "react-awesome-reveal";
import { Box, Button, Typography, IconButton, Input } from '@mui/material'
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import StarIcon from '@mui/icons-material/Star';
import { blue, grey } from '@mui/material/colors';

const DataCount = ({ icon = StarIcon, title = '', count = '', onClick = () => { } }) => {
  const Icon = icon
  return (
    <Box sx={{ m: 1, p: 2, backgroundColor: grey[100], borderRadius: 5, display: 'flex', flexDirection: 'row', alignItems: 'center', cursor: 'pointer' }} onClick={onClick}>
      <Box flexDirection="column" justifyContent={'center'} alignItems={'center'}>
        <Typography variant="h2">
          {count}
        </Typography>
        {title && <Typography variant="h6" sx={{ color: grey[500], marginRight: 4 }}>{title}</Typography>}
      </Box>
      {<Icon sx={{ fontSize: 96, color: grey[500] }} />}
    </Box>
  )
}

export default DataCount