import React from "react";
import {
  Paper,
  Typography,
  Box,
  Stack,
  IconButton,
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  TextField,
  Autocomplete,
  InputAdornment,
  alpha,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { api } from "../resources/config";
import { useQuery } from "react-query";
import { matchKeywords } from "../libraries/helper";
import {
  TTaskUnit,
  TExperiment,
  TTask,
  TSkipFlowControl,
} from "../resources/types";
import { blue, grey, orange } from "@mui/material/colors";
import TaskUnitListItem from "./TaskUnitListItem";
import {
  DragDropContext,
  Draggable,
  DraggingStyle,
  Droppable,
  DropResult,
  NotDraggingStyle,
  DroppableProvided,
  DroppableStateSnapshot,
  DraggableProvided,
  DraggableStateSnapshot,
} from "react-beautiful-dnd";
import { v4 } from "uuid";
import { TSignedInUserWithSetUser } from "../resources/types";
import { SignedInUserContext } from "../contexts/SignedInUserContext";
interface Item {
  id: string;
  code: string;
  description: string;
  skipFlowControl?: TSkipFlowControl;
}

const reorder = (
  list: Item[],
  startIndex: number,
  endIndex: number
): Item[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 8;

const getItemStyle = (
  isDragging: boolean,
  draggableStyle: DraggingStyle | NotDraggingStyle | undefined
): React.CSSProperties => ({
  // some basic styles to make the items look a bit nicer
  // userSelect: "none",
  // padding: grid,
  // margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  // background: isDragging ? alpha(grey[300], 0.6) : grey[100],
  // border: 'solid 1px orange',
  // borderRadius: 3,

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver: boolean): React.CSSProperties => ({
  background: isDraggingOver ? grey[100] : "none",
  padding: grid,
  width: "90%",
});

const TaskUnitArranger = ({
  taskUnits,
  skipFlowControls,
  onChange,
}: {
  taskUnits: string[];
  skipFlowControls?: TSkipFlowControl[];
  onChange?: (value: string[], skipFlowControls: TSkipFlowControl[]) => void;
}) => {
  const signedInUserWithSetUser: TSignedInUserWithSetUser | null =
    React.useContext(SignedInUserContext);
  const itemsRef = React.useRef<Array<HTMLDivElement | null>>([]);
  const [taskUnitFilter, setTaskUnitFilter] = React.useState("");
  const [selectedTaskUnits, setSelectedTaskUnits] = React.useState<Item[]>([]);

  const projectId = React.useMemo(
    () =>
      signedInUserWithSetUser?.projects?.find(
        (v) => v.code === signedInUserWithSetUser?.projectCodeInUse
      )?._id,
    [
      signedInUserWithSetUser?.projectCodeInUse,
      signedInUserWithSetUser?.projects,
    ]
  );

  const {
    isLoading: isTaskUnitLoading,
    isFetching: isTaskUnitFetching,
    error: taskUnitError,
    data: taskUnitData,
  } = useQuery(
    ["taskunit-data", projectId],
    () =>
      fetch(api.getTaskUnits({ projectId }), {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: `Bearer ${signedInUserWithSetUser?.user?.token}`,
        },
      }).then((res) => res.json()),
    {
      enabled: !!signedInUserWithSetUser?.user?.username && !!projectId,
    }
  );

  React.useEffect(() => {
    const items: Item[] = taskUnits.map((t, index) => ({
      id: v4(),
      code: t,
      description:
        taskUnitData?.find((tu: TTaskUnit) => tu.code === t)?.description ?? "",
      skipFlowControl: skipFlowControls?.[index] ?? {},
    }));
    setSelectedTaskUnits(items);
  }, [skipFlowControls, taskUnitData, taskUnits]);

  React.useEffect(() => {
    itemsRef.current = itemsRef.current.slice(0, selectedTaskUnits.length);
  }, [selectedTaskUnits]);

  const handleTaskUnitFilterChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setTaskUnitFilter(event.target.value);
  };

  const handleTaskUnitFilterClear = () => {
    setTaskUnitFilter("");
  };

  const filteredSortedTaskUnits = React.useMemo(() => {
    if (!taskUnitData) {
      return;
    }
    const taskUnitFilterKeywords = taskUnitFilter.trim().split(/\s+/);

    const filteredTaskUnits =
      taskUnitData?.filter &&
      taskUnitData?.filter(
        (v: TTaskUnit) =>
          !taskUnitFilter ||
          matchKeywords(
            [v.code, v.description, v.language, v.story ?? "", v.mode],
            taskUnitFilterKeywords
          )
      );

    return filteredTaskUnits?.sort((a: TTaskUnit, b: TTaskUnit) =>
      a.code.localeCompare(b.code)
    );
  }, [taskUnitData, taskUnitFilter]);

  const onDragEnd = (result: DropResult): void => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items: Item[] = reorder(
      selectedTaskUnits,
      result.source.index,
      result.destination.index
    );
    console.log(items);
    setSelectedTaskUnits(items);
    onChange &&
      onChange(
        items.map((t: Item) => t.code),
        items.map((t) => t.skipFlowControl ?? {})
      );
  };

  const handleDeleteTaskUnit = (id: string) => {
    const newSelectedTaskUnits = selectedTaskUnits.filter(
      (t: Item) => t.id !== id
    );
    setSelectedTaskUnits(newSelectedTaskUnits);
    onChange &&
      onChange(
        newSelectedTaskUnits.map((t: Item) => t.code),
        newSelectedTaskUnits.map((t: Item) => t.skipFlowControl ?? {})
      );
  };

  const handleAddTaskUnit = (code: string, description: string) => {
    const newSelectedTaskUnits = [
      ...selectedTaskUnits,
      {
        id: v4(),
        code,
        description,
        skipFlowControl: {
          enabled: false,
        },
      },
    ];
    setSelectedTaskUnits(newSelectedTaskUnits);
    onChange &&
      onChange(
        newSelectedTaskUnits.map((t: Item) => t.code),
        newSelectedTaskUnits.map((t: Item) => t.skipFlowControl ?? {})
      );
    setTimeout(() => {
      itemsRef.current[selectedTaskUnits.length - 1]?.scrollIntoView({
        behavior: "smooth",
      });
    }, 500);
  };

  const handleSkipFlowControlChange = (index: number, s: TSkipFlowControl) => {
    console.debug(
      `handleSkipFlowControlChange: skipFlowControl = ${JSON.stringify(s)}`
    );
    const newSelectedTaskUnits = selectedTaskUnits.map((v) => ({ ...v }));
    newSelectedTaskUnits[index].skipFlowControl = {
      ...s,
    };
    onChange &&
      onChange(
        newSelectedTaskUnits.map((t: Item) => t.code),
        newSelectedTaskUnits.map((t: Item) => t.skipFlowControl ?? {})
      );
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{
        padding: 2,
        minHeight: "60vh",
        borderColor: orange[300],
        borderWidth: 1,
        borderRadius: 1,
        borderStyle: "solid",
      }}
    >
      <Typography variant="h4" sx={{ mb: 2 }}>
        Task units
      </Typography>
      <Typography
        variant="body1"
        sx={{ mb: 1 }}
      >{`${selectedTaskUnits.length} selected`}</Typography>
      {selectedTaskUnits.length === 0 && (
        <Typography variant="body2">
          Select task units from the list on the right. Click the "+" button to
          add.
        </Typography>
      )}
      {selectedTaskUnits.length > 0 && (
        <Typography variant="body2">
          Add more task units from the full list on the right. Drag and drop to
          arrange the order. Click "X" button to remove a task unit.
        </Typography>
      )}
      <Box
        display="flex"
        flex={1}
        flexDirection="row"
        justifyContent="space-between"
      >
        <Box
          display="flex"
          flexDirection={"column"}
          flex={1}
          alignItems="center"
          sx={{ p: 1, position: "relative", overflow: "auto" }}
        >
          {selectedTaskUnits.length > 0 && (
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
              }}
            >
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                  {(
                    provided: DroppableProvided,
                    snapshot: DroppableStateSnapshot
                  ): JSX.Element => (
                    <Box
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      style={getListStyle(snapshot.isDraggingOver)}
                    >
                      {selectedTaskUnits.map((item, index) => (
                        <Draggable
                          key={item.id}
                          draggableId={item.id}
                          index={index}
                        >
                          {(
                            provided: DraggableProvided,
                            snapshot: DraggableStateSnapshot
                          ): JSX.Element => (
                            <Box
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                              display="flex"
                              flexDirection="row"
                              alignItems="center"
                              justifyContent="space-between"
                            >
                              {!snapshot.isDragging && (
                                <Box
                                  display="flex"
                                  flexDirection="column"
                                  alignItems="flex-end"
                                  sx={{
                                    mr: 1,
                                    mt: 1,
                                    width: "2ch",
                                    fontFamily: '"Roboto Condensed"',
                                  }}
                                >
                                  <Typography variant="body1">{`${
                                    index + 1
                                  }.`}</Typography>
                                </Box>
                              )}
                              <div
                                style={{ flex: 1 }}
                                ref={(el) =>
                                  (itemsRef.current[index] =
                                    el as HTMLDivElement)
                                }
                              >
                                <TaskUnitListItem
                                  isDragging={snapshot.isDragging}
                                  code={item.code}
                                  description={item.description}
                                  showDragHandle={!snapshot.isDragging}
                                  skipFlowControl={item.skipFlowControl}
                                  onSkipFlowControlChange={(
                                    s: TSkipFlowControl
                                  ) => handleSkipFlowControlChange(index, s)}
                                />
                              </div>
                              {!snapshot.isDragging && (
                                <IconButton
                                  sx={{ ml: 2 }}
                                  onClick={() => handleDeleteTaskUnit(item.id)}
                                >
                                  <ClearIcon />
                                </IconButton>
                              )}
                            </Box>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </Box>
                  )}
                </Droppable>
              </DragDropContext>
            </Box>
          )}
        </Box>
        <Box
          flex={1}
          display="flex"
          flexDirection={"column"}
          sx={{
            mt: 1,
            ml: 2,
            p: 1,
            pr: 2,
            borderColor: grey[300],
            borderWidth: 1,
            borderRadius: 1,
            borderStyle: "solid",
          }}
        >
          <Typography variant="h5">All task units</Typography>
          <Box sx={{ m: 2, ml: 0 }}>
            <TextField
              margin="none"
              fullWidth
              size="small"
              variant="standard"
              value={taskUnitFilter}
              label="Filter task units"
              onChange={handleTaskUnitFilterChange}
              placeholder="e.g. bird comprehension cantonese"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleTaskUnitFilterClear}>
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              helperText={
                filteredSortedTaskUnits && taskUnitFilter
                  ? `${filteredSortedTaskUnits?.length} task units filtered`
                  : `Total ${filteredSortedTaskUnits?.length} task units`
              }
            />
          </Box>
          <Box
            flexDirection={"column"}
            flex={1}
            sx={{ position: "relative", overflow: "auto" }}
          >
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 16,
                bottom: 0,
              }}
            >
              {filteredSortedTaskUnits?.map((v: TTaskUnit) => (
                <TaskUnitListItem
                  key={v.code}
                  code={v.code}
                  description={v.description}
                  showAddIcon={true}
                  onAdd={() => handleAddTaskUnit(v.code, v.description)}
                />
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
      {/*taskUnitData && <Autocomplete
          multiple
          options={filteredSortedTaskUnits}
          getOptionLabel={(option: TTaskUnit) => `${option?.code}: ${option?.description}`}
          value={taskUnits.map(c => taskUnitData.find((v: TTaskUnit) => v.code === c))}
          isOptionEqualToValue={(option, value) => option === value}
          filterSelectedOptions
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label="Task units"
              placeholder="Click or type to search"
            />
          )}
          onChange={handleChange}
        />*/}
    </Box>
  );
};

export default TaskUnitArranger;
