import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Integration from './Integration';
import MaintenancePage from './pages/MaintenancePage';
import { QueryCache, QueryClient, QueryClientProvider } from "react-query";
import { ConfirmationServiceProvider } from "./services/ConfirmationService";
import { CustomSnackbarServiceProvider } from "./services/CustomSnackbarService";
// import reportWebVitals from './reportWebVitals';

const underMaintenance = false

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
  queryCache: new QueryCache({
    onError: async (error, query) => {
      console.log(`queryClient onError`)
      if ((error as Error).message === 'Unauthorized') {
        console.log(`queryClient onError Unauthorized`)
      } else {
        console.log(`Other error: ${(error as Error).message}`)
      }
    },
    onSuccess: data => {
      // console.log(`queryClient onSuccess`)
      // console.log(data)
    }
  })
});

ReactDOM.render(
  underMaintenance ? <MaintenancePage /> :
  <CustomSnackbarServiceProvider>
    <ConfirmationServiceProvider>
      <QueryClientProvider client={queryClient}>
        <Integration/>
      </QueryClientProvider>
    </ConfirmationServiceProvider>
  </CustomSnackbarServiceProvider>,
  document.getElementById('root')
);

// ReactDOM.render(
//   <React.StrictMode>
//     <Integration />
//   </React.StrictMode>,
//   document.getElementById('root')
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
