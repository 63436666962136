import {
  Select,
  MenuItem,
  ListSubheader,
  SelectChangeEvent,
  FormControl,
  InputLabel,
} from "@mui/material";
import React from "react";

type TResponseType = {
  type: string;
  description: string;
};

const responseTypes: TResponseType[] = [
  { type: "audio", description: "Audio Response" },
  { type: "button", description: "Button Response" },
  { type: "keyboard", description: "Keyboard Response" },
  { type: "text", description: "Text Response" },
];

const TaskUnitJsPsychPluginSelector = ({
  disabled = false,
  label,
  plugInTypes,
  selectedPlugInType,
  onChange,
}: {
  disabled?: boolean;
  label?: string;
  plugInTypes: { id: string; text: string; responseType: string }[];
  selectedPlugInType: string;
  onChange: (value: string) => void;
}) => {
  const handleChange = (event: SelectChangeEvent<string>) => {
    onChange(event.target.value);
  };

  const menuItems = React.useMemo(() => {
    const items: React.ReactNode[] = [];
    responseTypes.forEach((responseType: TResponseType) => {
      items.push(<ListSubheader key={responseType.type}>{responseType.description}</ListSubheader>);
      plugInTypes
        .filter((v) => v.responseType === responseType.type)
        .forEach((v) => {
          items.push(
            <MenuItem key={v.id} value={v.id}>
              {" "}
              {v.text}{" "}
            </MenuItem>
          );
        });
    });
    return items;
  }, [plugInTypes]);

  return (
    <FormControl variant="outlined">
      <InputLabel id="plugin-selector-label">{label ?? ""}</InputLabel>
      <Select
        disabled={disabled}
        labelId="plugin-selector-label"
        value={selectedPlugInType}
        onChange={handleChange}
        label={label ?? ""}
      >
        {menuItems}
      </Select>
    </FormControl>
  );
};

export default TaskUnitJsPsychPluginSelector;
