import React from "react";
import { Paper, Typography, Box, IconButton, Dialog, DialogTitle, List, ListItem, ListItemAvatar, ListItemText, Avatar } from '@mui/material'
import {
  Routes,
  Route,
  Link,
  useNavigate,
} from 'react-router-dom';
import { OverridableComponent, } from '@mui/types'
import { SvgIconTypeMap } from '@mui/material'
import { blue, orange, grey } from '@mui/material/colors';
import { Fade } from "react-awesome-reveal";

type IconType = OverridableComponent<SvgIconTypeMap<{}, "svg">>;

interface IMenuItem {
  title: string,
  description: string,
  Icon: IconType & {
    muiName: string;
  },
  onMouseEnter?: () => void,
  onMouseLeave?: () => void,
  onClick?: () => void,
  active?: boolean;
}

function MenuItem({
  title,
  description,
  Icon,
  onMouseEnter,
  onMouseLeave,
  onClick,
  active = false
}: IMenuItem) {


  return (
    <Box
      sx={{ margin: 2, padding: 2, borderRadius: 1, cursor: 'pointer', display: 'flex', flexDirection: 'row', alignItems: 'center' }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
      boxShadow={active ? 8 : 2}
    >
      <Icon sx={{ fontSize: 64, color: active ? orange[300] : blue[500], mr: 2, transition: "transform 0.8s", transform: active ? "scale(1.2) rotate(5deg)" : "none" }} />
      <Box display='flex' flexDirection='column'>
        <Typography variant="h5" sx={{transition: "transform 0.8s", transform: active ? "scale(1.05)" : "none"}}>
          {title}
        </Typography>
        <Typography variant="body2" sx={{ maxWidth: 240, color: grey[600] }}>
          {description}
        </Typography>
      </Box>
    </Box>
  );
}

export default MenuItem;
