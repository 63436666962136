import { Select, MenuItem, SelectChangeEvent, FormControl, InputLabel, FormHelperText } from '@mui/material'
import { languages } from '../resources/lexicon'

const ExperimentLanguageSelector = ({ disabled = false, label, language, required = false, helperText, onChange }: {
  disabled?: boolean;
  label?: string;
  language: string;
  required?: boolean;
  helperText?: string;
  onChange: (value: string) => void
}) => {
  const handleChange = (event: SelectChangeEvent<string>) => {
    onChange(event.target.value)
  }

  return (

    <FormControl variant="outlined" required={required}>
      <InputLabel id="language-selector-label">{label ?? ''}</InputLabel>
      <Select
        disabled={disabled}
        labelId="language-selector-label"
        value={language}
        onChange={handleChange}
        label={label ?? ''}
      >
        {languages.map(v => <MenuItem key={v.id} value={v.id} > {v.label} </MenuItem>)}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>)
}

export default ExperimentLanguageSelector