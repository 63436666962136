import React from "react";
import { Select, MenuItem, SelectChangeEvent, FormControl, InputLabel, FormHelperText, Stack } from '@mui/material'
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import { TSignedInUserWithSetUser } from '../resources/types'
import { SignedInUserContext } from '../contexts/SignedInUserContext';
import { red } from '@mui/material/colors'

const ProjectInUseSelector = ({
  readOnly = true,
  // helperText = ''
}: {
  readOnly?: boolean;
  // helperText?: string;
}) => {
  const signedInUserWithSetUser: TSignedInUserWithSetUser | null = React.useContext(SignedInUserContext)

  const handleChange = (event: SelectChangeEvent<string>) => {
    console.log(`ProjectInUseSelector: ${event.target.value}`)
    signedInUserWithSetUser?.setProjectCodeInUse && signedInUserWithSetUser?.setProjectCodeInUse(event.target.value)
  }

  const renderSelected = (projectCode: string) => {
    const project = signedInUserWithSetUser?.projects?.find(v => v.code === projectCode)
    return (
      project ? <Stack flexDirection="row"><AccountTreeIcon color="primary" sx={{ mr: 1 }} />{`${project.code}: ${project.name}`}</Stack> : null
    )
  }

  const projectFound = React.useMemo(
    () => {
      let found = false
      if (signedInUserWithSetUser?.projectCodeInUse && signedInUserWithSetUser?.projects && signedInUserWithSetUser?.projects?.length > 0) {
        if (signedInUserWithSetUser?.projects.find(p => p.code === signedInUserWithSetUser?.projectCodeInUse)) {
          found = true
        }
      }
      return found
    },
    [signedInUserWithSetUser?.projectCodeInUse, signedInUserWithSetUser?.projects]
  )

  return (
    signedInUserWithSetUser && <FormControl variant="outlined" fullWidth>
      <InputLabel id="project-in-use-selector-label">Project in use</InputLabel>
      <Select
        disabled={readOnly || signedInUserWithSetUser?.projects?.length === 0}
        labelId="project-in-use-selector-label"
        value={signedInUserWithSetUser?.projectCodeInUse || ''}
        onChange={handleChange}
        renderValue={renderSelected}
        label={'Project in use'}
      >
        {signedInUserWithSetUser?.projects?.map(v => <MenuItem key={v.code} value={v.code} > {`${v.code}: ${v.name}`} </MenuItem>)}
      </Select>
      {!projectFound && signedInUserWithSetUser?.projects && signedInUserWithSetUser?.projects?.length > 0 && <FormHelperText sx={{fontSize: 28, color: red[500]}}>{'Please select a project to perform project-specific operations'}</FormHelperText>}
      {signedInUserWithSetUser?.projects?.length === 0 && <FormHelperText sx={{fontSize: 24, color: red[500]}}>{`No projects assigned to user "${signedInUserWithSetUser?.user?.username}"`}</FormHelperText>}
    </FormControl>
  )
};

export default ProjectInUseSelector
