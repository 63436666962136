import React from 'react'
import {
  Box, Dialog, DialogContent, DialogTitle, TextField, Typography, Stack, DialogActions, Button
} from '@mui/material'
import { useQuery } from "react-query";
import { red } from '@mui/material/colors'
import { api, roles } from "../resources/config"
import { TSignedInUserWithSetUser } from '../resources/types'
import { SignedInUserContext } from '../contexts/SignedInUserContext';

const UserProfile = ({ open, onClose }: {
  open: boolean,
  onClose: () => void
}) => {
  const signedInUserWithSetUser: TSignedInUserWithSetUser | null = React.useContext(SignedInUserContext)
  const { isLoading, isFetching, isError, error, data: rawUserData, refetch: refetchUserData } = useQuery(
    ['user-data', signedInUserWithSetUser?.user?.username, 1],
    () =>
      fetch(api.getUser(signedInUserWithSetUser?.user?.username), {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Authorization": `Bearer ${signedInUserWithSetUser?.user?.token}`
        }
      })
        .then((res) => {
          console.log(`status = ${res.status}`)
          if (res.status === 401) {
            throw new Error('Unauthorized')
          }
          return res.json()
        }),
    {
      enabled: !!signedInUserWithSetUser?.user?.username,
    }
  );

  const userData = React.useMemo(
    () => {
      const { username = '', name = '', role = '', note = '', projects = [] } = rawUserData?.docs?.[0] ?? {}
      return {
        username, name, role, note,
        projects: projects.map((v: any) => ({
          code: v.code,
          name: v.name
        }))
      }
    },
    [rawUserData]
  )

  return (
    <Dialog fullWidth maxWidth="md" onClose={onClose} open={open}>
      <DialogTitle>{`${userData.username}`}</DialogTitle>
      <DialogContent>
        {userData && <Stack sx={{ pt: 2 }} spacing={3}>
          <TextField
            fullWidth
            variant="standard"
            label="Name"
            value={userData.name}
            InputProps={{
              readOnly: true
            }}
          />
          <TextField
            fullWidth
            variant="standard"
            label="Note"
            value={userData.note}
            InputProps={{
              readOnly: true
            }}
          />
          <TextField
            fullWidth
            variant="standard"
            label="Role"
            value={roles.find(v => v.id === userData.role)?.label ?? ''}
            InputProps={{
              readOnly: true
            }}
          />

          <TextField
            fullWidth
            variant="standard"
            label="Projects with access"
            value={userData.projects.map((v: any) => v.code).join(', ')}
            InputProps={{
              readOnly: true
            }}
          />
        </Stack>}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  )
}
export default UserProfile