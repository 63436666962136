import React from "react";
import { Paper, Button, Typography, Box, TextField, InputAdornment, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, List, ListItem, ListItemAvatar, ListItemText, Avatar, Grid, Divider, Stack } from '@mui/material'
import { DateRangePicker, DateRange } from "materialui-daterange-picker"
import { Table, TableHead, TableRow, TableCell, TableBody, Tooltip } from '@mui/material'
import moment from 'moment'
import { useQuery } from "react-query";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import RefreshIcon from '@mui/icons-material/Refresh';
import EditIcon from '@mui/icons-material/Edit';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import DownloadIcon from '@mui/icons-material/Download';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ArticleIcon from '@mui/icons-material/Article';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import CloseIcon from '@mui/icons-material/Close'
import DeleteIcon from '@mui/icons-material/Delete'
import MicIcon from '@mui/icons-material/Mic';
import WysiwygIcon from '@mui/icons-material/Wysiwyg';
import DataCount from '../components/DataCount'
import WaveSurfer from "wavesurfer.js";
import { blue } from '@mui/material/colors';
import { Fade } from "react-awesome-reveal";
import { api, audioPath } from "../resources/config"
import { TAudioData, TAudioFile } from "../resources/types"
import { useTable, useSortBy } from "react-table";
import SurveyResponse from "../components/SurveyResponse";
import { Link } from "react-router-dom";
import { matchKeywords } from "../libraries/helper"
import ResponsiveAppBar from "../components/AppBar"
import MenuBreadCrumbs from "../components/MenuBreadCrumbs";
import ProjectInUseSelector from "../components/ProjectInUseSelector";
import { TSignedInUserWithSetUser } from '../resources/types'
import { SignedInUserContext } from '../contexts/SignedInUserContext';
import { useConfirmation } from "../services/ConfirmationService";
import { useSnackbar } from "../services/CustomSnackbarService";
import CurtainWithProgress from "../components/CurtainWithProgress";

const refreshInterval = 60000

function TextDataPage() {
  const signedInUserWithSetUser: TSignedInUserWithSetUser | null = React.useContext(SignedInUserContext)
  const [open, setOpen] = React.useState(false);
  const [dateRange, setDateRange] = React.useState<DateRange>({
    startDate: moment().startOf('month').toDate(),
    endDate: moment().endOf('month').toDate()
  });
  const [currentPage, setCurrentPage] = React.useState(1)
  const [pageSize, setPageSize] = React.useState(5)
  const [selectedTextData, setselectedTextData] = React.useState<any>(null)
  const [sortColumn, setSortColumn] = React.useState('')
  const [isSortedDesc, setIsSortedDesc] = React.useState<boolean | undefined>(false)
  const [textDataFilter, setTextDataFilter] = React.useState('')
  const [search, setSearch] = React.useState('')
  const searchTextField = React.useRef<HTMLInputElement>(null)
  const [isProcessing, setIsProcessing] = React.useState(false);
  const confirm = useConfirmation();
  const snackbar = useSnackbar()

  const projectId = React.useMemo(
    () => signedInUserWithSetUser?.projects?.find(v => v.code === signedInUserWithSetUser?.projectCodeInUse)?._id,
    [signedInUserWithSetUser?.projectCodeInUse, signedInUserWithSetUser?.projects]
  )

  const toggle = () => setOpen(!open);
  const rangeDisplay = React.useMemo(
    () => `${moment(dateRange.startDate).format('YYYY-MM-DD')} ~ ${moment(dateRange.endDate).format('YYYY-MM-DD')}`,
    [dateRange]
  )

  const { isLoading, isFetching, error, data, refetch } = useQuery(
    ['text-data', projectId, currentPage, pageSize, sortColumn, isSortedDesc, search],
    () =>
      fetch(api.getTextData({
        projectId,
        page: currentPage.toString(),
        limit: pageSize.toString(),
        sortColumn: sortColumn || '',
        isSortedDesc: isSortedDesc?.toString() || 'false',
        search
      }), {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Authorization": `Bearer ${signedInUserWithSetUser?.user?.token}`
        }
      })
        .then((res) => res.json()),
    {
      enabled: !!signedInUserWithSetUser?.user?.username && !!projectId,
    }
  );

  React.useEffect(
    () => {
      setTimeout(
        () => searchTextField.current?.focus(), 100
      )
    },
    [data]
  )


  const handleTextDataFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTextDataFilter(event.target.value)
  }

  const handlePressEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  const handleTextDataFilterClear = () => {
    setTextDataFilter('')
    setCurrentPage(1)
    setSearch('')
  }

  const handleSearch = () => {
    setCurrentPage(1)
    setSearch(textDataFilter)
  }

  const handleDataRefresh = () => {
    refetch()
  }

  const handleDownload = () => {
    const startDate = moment(dateRange.startDate).format('YYYY-MM-DD')
    const endDate = moment(dateRange.endDate).format('YYYY-MM-DD')
    const url = api.downloadTextData({
      projectId, startDate, endDate, search
    })
    window.open(url, "_blank")
  }

  const handleDeleteTextData = (id: string) => {
    if (!id) {
      return
    }
    const target = data.docs.find((v: any) => v.id === id)
    if (!target) {
      return
    }
    const { email, uploadedDate } = target
    console.log(`handleDeleteTextData() id=${id}, email=${email}, uploadedDate=${uploadedDate}`)
    confirm({
      variant: "danger",
      title: `Delete text data`,
      description: `Are you sure to delete this text data submitted by participant "${email}" on ${moment(uploadedDate).format("YYYY-MM-DD HH:mm")}?`,
      cancelButtonText: "No",
      agreeButtonText: "Yes, go ahead to delete"
    }).then(() => {
      setIsProcessing(true)
      console.log(`Go ahead to delete ${id}`)
      fetch(api.deleteTextData({ projectId, id }), {
        method: 'DELETE',
        credentials: "include",
        headers: {
          // "Content-Type": "application/json;charset=UTF-8",
          "Authorization": `Bearer ${signedInUserWithSetUser?.user?.token}`
        }
      })
        .then(res => res.json())
        .then(json => {
          setIsProcessing(false)
          if (json.error) {
            throw new Error(json.message || json.error)
          }
          snackbar({
            severity: 'success',
            message: `Text data deleted successfully`
          })
          refetch()
        })
        .catch(error => {
          setIsProcessing(false)
          confirm({
            variant: 'error',
            title: `Error deleting text data`,
            description: `Deletion of text data failed: ${(error as Error)?.toString()}`
          })
        })
    })
  }

  const handleResponseClose = () => {
    setselectedTextData(null)
  }

  const handleSort = (newSortColumn: string, newIsSortedDesc?: boolean) => {
    console.log(`in handleSort(),newSortColumn=${newSortColumn}, newIsSortedDesc=${newIsSortedDesc} `)
    setSortColumn(newSortColumn)
    setIsSortedDesc(newIsSortedDesc)
  }

  const disabled = React.useMemo(
    () => {
      const disabledValue = isLoading || isFetching || isProcessing
      console.log(`setting "disabled", isLoading=${isLoading}, isFetching = ${isFetching}, isProcessing=${isProcessing}, disabledValue=${disabledValue}`)
      return disabledValue
    },
    [isLoading, isFetching, isProcessing]
  )

  const columns = React.useMemo(() => {
    return [
      {
        Header: "Participant",
        accessor: "participant.email",
        width: '10%'
      },
      {
        Header: "Date",
        accessor: "uploadedDate",
        Cell: ({ row, value }: { row: any, value: any }) => {
          return moment(value).format('YYYY-MM-DD HH:mm:ss')
        },
        width: '15%'
      },
      {
        Header: "Experiment",
        accessor: "taskId",
        // Cell: ({ row, value }: { row: any, value: any }) => {
        //   return moment(value).format('YYYY-MM-DD HH:mm:ss')
        // },
        width: '15%'
      },
      {
        Header: "Task Unit",
        accessor: "subtaskId",
        // Cell: ({ row, value }: { row: any, value: any }) => {
        //   return moment(value).format('YYYY-MM-DD HH:mm:ss')
        // },
        width: '10%'
      },
      // {
      //   Header: "Language",
      //   accessor: "language",
      //   // Cell: ({ row, value }: { row: any, value: any }) => {
      //   //   return moment(value).format('YYYY-MM-DD HH:mm:ss')
      //   // },
      //   width: '10%'
      // },
      // {
      //   Header: "Length (s)",
      //   accessor: "length",
      //   // Cell: ({ row, value }: { row: any, value: any }) => {
      //   //   return moment(value).format('YYYY-MM-DD HH:mm:ss')
      //   // },
      //   width: '10%'
      // },
      {
        Header: "Response time (ms)",
        accessor: "responseTime",
        width: "10%",
      },
      {
        Header: "Brief content",
        accessor: "data",
        Cell: ({ row, value }: { row: any, value: any }) => {
          if (value && typeof value === 'object') {
            return Object.keys(value).slice(0, 10).map((key: string) => `${key}: "${value[key].length > 50 ? value[key].substr(0, 50) + "..." : value[key]}"`).join(" / ")
          } else if (typeof value === 'string') {
            return value
          } else if (typeof value !== 'undefined') {
            return JSON.stringify(value)
          } else {
            return '-'
          }
        },
        width: '15%'
      },
      {
        Header: "",
        id: "download",
        width: '5%',
        Cell: ({ row, value }: { row: any, value: string }) => {
          const id = row.original._id ?? ''
          return id ? <a href={api.downloadSingleTextData({ projectId, id })}><DownloadIcon /></a> : null
        }
      },
      {
        Header: "",
        id: "view",
        width: '5%',
        Cell: ({ row, value }: { row: any, value: string }) => {
          const email = row.allCells.find((cell: any) => cell.column.id === 'participant.email').value as string
          const uploadedDate = row.allCells.find((cell: any) => cell.column.id === 'uploadedDate').value as Date
          const data = row.original.data ?? ''
          return <Tooltip title="View data"><IconButton onClick={() => setselectedTextData({
            email,
            uploadedDate,
            data
          })}><ArticleIcon /></IconButton></Tooltip>
        }
      },
      ...(signedInUserWithSetUser?.accessRight?.canDeleteResponseData ? [{
        Header: "",
        id: "delete",
        width: '5%',
        Cell: ({ row, value }: { row: any, value: string }) => {
          const { id } = row.original
          return <IconButton disabled={disabled} onClick={() => handleDeleteTextData(id)}><DeleteIcon /></IconButton>
        }
      }] : []),
    ];
  }, [disabled, signedInUserWithSetUser?.accessRight?.canDeleteResponseData]);

  const filteredTextData = React.useMemo(
    () => {
      // const audioDataFilterKeywords = textDataFilter.trim().split(/\s+/)
      // const filteredTextData = data?.docs.filter((v: TAudioFile) => !textDataFilter ||
      // matchKeywords([v.taskId, v.subtaskId, v.transcription ?? '', v.participant.email, v.language ?? ''], audioDataFilterKeywords))
      return {
        ...data,
        // docs: filteredTextData
      }
    },
    [data]
  )



  return (
    <Paper elevation={0}>
      <ResponsiveAppBar />
      <MenuBreadCrumbs items={['home', 'dashboard', 'textdata']} />
      <Stack sx={{ mt: 2, ml: 4, mr: 4 }}>
        <Box sx={{ mt: 4, mb: 1 }}><ProjectInUseSelector readOnly={false} /></Box>
        <Typography variant="h3" sx={{ mb: 4 }}>
          Text Data
        </Typography>

        <Grid container justifyContent="space-between">
          <Grid item sm={12} md={data?.totalDocs > 0 ? 6 : 12}>
            <TextField
              fullWidth
              label="Search"
              variant="outlined"
              margin="none"
              size="small"
              inputRef={searchTextField}
              value={textDataFilter}
              onChange={handleTextDataFilterChange}
              onKeyPress={handlePressEnter}
              placeholder={`e.g. "cantonese cat"`}
              helperText={'The filter applies only to characters, not symbols, and is not case-sensitive. If filtering multiple keywords, use a space to separate them, and all criteria must be met.'}
              InputProps={{
                endAdornment: [
                  <InputAdornment position="end" key='clear'><IconButton onClick={handleTextDataFilterClear}><ClearIcon /></IconButton></InputAdornment>,
                  <Divider key="divider" orientation="vertical" variant="middle" flexItem />,
                  <InputAdornment position="end" key='search'><IconButton color="primary" onClick={handleSearch}><SearchIcon /></IconButton></InputAdornment>
                ]
              }}
            />
          </Grid>
          {data && data.totalDocs > 0 && <Grid item sm={12} md={6}>
            <Box display="flex" flexDirection="row" alignItems="flex-end" justifyContent="flex-end">
              <TextField
                fullWidth
                variant="standard"
                label={'Select date range for batch download'}
                sx={{ ml: 2 }}
                value={rangeDisplay}
                InputProps={{
                  readOnly: true,
                  endAdornment: <InputAdornment position="end"><IconButton onClick={toggle}><EditIcon /></IconButton></InputAdornment>,
                }}
                onClick={toggle}
              />
              <IconButton onClick={handleDownload} sx={{ marginLeft: 4 }}><DownloadIcon /></IconButton>
            </Box>
          </Grid>}
          {data && data.totalDocs === 0 && <Box sx={{ mt: 1, mr: 4 }}>
            <Typography variant="body1">No text data found</Typography>
          </Box>}
        </Grid>

        <DateRangePicker
          open={open}
          initialDateRange={dateRange}
          toggle={toggle}
          onChange={(range) => {
            setOpen(false)
            setDateRange(range)
          }}
        />

        {data && data.totalDocs > 0 && <Box sx={{ mt: 4 }}>
          <Box display="flex" flexDirection="row" alignItems="flex-start" justifyContent="space-between">
            <Box display="flex" flexDirection="row" alignItems="center">
              <IconButton onClick={handleDataRefresh}>
                <RefreshIcon />
              </IconButton>
              <Typography variant="caption" sx={{ mr: 4 }}>{
                (textDataFilter ? `Found ${data.totalDocs} records` : `Total: ${data.totalDocs} records.`) + ` Record #${(data.page - 1) * data.limit + 1} to #${(data.page - 1) * data.limit + data.docs.length}`
              }</Typography>
            </Box>
            <Box display="flex" flexDirection="row" alignItems="flex-end">
              <Button startIcon={<ArrowBackIcon />} disabled={!data.hasPrevPage} onClick={() => setCurrentPage(data.prevPage)}>Previous</Button>
              <Button startIcon={<ArrowForwardIcon />} disabled={!data.hasNextPage} onClick={() => setCurrentPage(data.nextPage)}>Next</Button>
            </Box>
          </Box>
          <TextDataTable
            columns={columns}
            data={filteredTextData.docs}
            sortColumn={sortColumn}
            isSortedDesc={isSortedDesc}
            onSort={handleSort}
          />
        </Box>}
      </Stack>
      {selectedTextData && <Dialog fullWidth={true}
        maxWidth="md" onClose={handleResponseClose} open={!!selectedTextData}>
        <DialogTitle>{selectedTextData.email}</DialogTitle>
        <DialogContent>
          <Box sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            mr: 2
          }}>
            <Typography variant="caption" sx={{ mb: 1 }}>{`Received on ${moment(selectedTextData.uploadedDate).format('YYYY-MM-DD HH:mm:ss')}`}</Typography>
            {typeof selectedTextData.data === 'object' ? <SurveyResponse response={selectedTextData.data} /> : <Typography variant="body1">{selectedTextData.data}</Typography>}
          </Box>
        </DialogContent>
        <DialogActions style={{ marginRight: 18 }}>
          <Button variant="contained" startIcon={<CloseIcon />} onClick={handleResponseClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>}
      {disabled && <CurtainWithProgress open={disabled} />}
    </Paper>
  );
}

const TextDataTable = (
  {
    columns, data, onSort, sortColumn, isSortedDesc
  }:
    {
      columns: any, data: any, onSort: (sortColumn: string, isSortedDesc?: boolean) => void,
      sortColumn: string,
      isSortedDesc: boolean | undefined
    }
) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data
    }
  );

  return (
    <Table {...getTableProps()}>
      <TableHead>
        {headerGroups.map((headerGroup) => (
          <TableRow {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column: any) => <TableCell
              {...column.getHeaderProps()}
              onClick={(event) => {
                if (column.id === sortColumn && isSortedDesc) {
                  onSort('')
                } else if (column.id === sortColumn && isSortedDesc === false) {
                  onSort(column.id, true)
                } else {
                  onSort(column.id, false)
                }
              }}
              sx={{ width: column.width }}
            >
              {column.render("Header")}
              <span>
                {column.id === sortColumn
                  ? isSortedDesc
                    ? ' 🔽'
                    : ' 🔼'
                  : ''}
              </span>
            </TableCell>)}
          </TableRow>
        ))}
      </TableHead>
      <TableBody {...getTableBodyProps()}>
        {rows.map((row: any, i: number) => {
          prepareRow(row);
          return (
            <TableRow {...row.getRowProps()}>
              {row.cells.map((cell: any) => <TableCell {...cell.getCellProps()}>
                {cell.render("Cell")}
              </TableCell>)}
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  )
}

export default TextDataPage;
