import React from "react";
import { Paper, Typography, Box, Stack, IconButton, Dialog, DialogTitle, List, ListItem, ListItemAvatar, ListItemText, Avatar, TextField, Autocomplete, InputAdornment } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { api } from "../resources/config"
import { useQuery } from 'react-query'
import moment from 'moment'
import { blue } from '@mui/material/colors';
import { matchKeywords } from "../libraries/helper"
import { TMedia } from "../resources/types"
import TypeIcon from "./TypeIcon"
import { TSignedInUserWithSetUser } from '../resources/types'
import { SignedInUserContext } from '../contexts/SignedInUserContext';

const MediaSelector = ({
  disabled = false,
  label,
  helperText,
  placeholder,
  media,
  mediaType,
  onChange,
}: {
  disabled?: boolean;
  label: string;
  helperText?: string;
  placeholder?: string;
  media?: string;
  mediaType?: string[];
  onChange?: (media: TMedia | null) => void;
}) => {
  const signedInUserWithSetUser: TSignedInUserWithSetUser | null = React.useContext(SignedInUserContext)
  const [mediaDataFilter, setTaskUnitFilter] = React.useState('');

  const projectId = React.useMemo(
    () => signedInUserWithSetUser?.projects?.find(v => v.code === signedInUserWithSetUser?.projectCodeInUse)?._id,
    [signedInUserWithSetUser?.projectCodeInUse, signedInUserWithSetUser?.projects]
  )

  const { isLoading: isMediaDataLoading, isFetching: isMediaDataFetching, error: mediaError, data } = useQuery(
    ['media-data', projectId],
    () =>
      fetch(api.getMediaData({projectId, limit: '-1'}), {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Authorization": `Bearer ${signedInUserWithSetUser?.user?.token}`
        }
      })
        .then((res) => res.json()),
    {
      enabled: !!signedInUserWithSetUser?.user?.username && !!projectId,
    }
  );

  const mediaData = React.useMemo(
    () => data?.docs,
    [data]
  )

  const handleChange = (event: React.SyntheticEvent<Element, Event>, media: TMedia | null) => {
    console.log(media)
    onChange && onChange(media)
  }

  const filteredSortedMediaData = React.useMemo(
    () => {
      const mediaFilterKeywords = mediaDataFilter.trim().split(/\s+/)

      const filteredMediaData = mediaData?.filter((v: TMedia) => !mediaType || mediaType.includes(v.mimeTypeMajor)).filter((v: TMedia) => !mediaDataFilter ||
        matchKeywords([v.code, v.note], mediaFilterKeywords)
      )

      return filteredMediaData?.sort((a: TMedia, b: TMedia) => a.code.localeCompare(b.code))
    },
    [mediaData, mediaDataFilter, mediaType]
  )

  return (
    <Box flexDirection={'row'}>
      {mediaData && <Autocomplete
        disabled={disabled}
        id="tags-outlined"
        options={filteredSortedMediaData}
        renderOption={(props, option: TMedia) => <li {...props}><Stack display="flex" flexDirection={'row'} alignItems={'center'}>
          <Box sx={{ mr: 2 }} display="flex" alignItems="center" flexDirection={'row'}><TypeIcon type={option.mimeTypeMajor} /></Box>
          <Typography variant="body1">{`${option?.code}: ${option?.note}`}</Typography>
        </Stack></li>}
        getOptionLabel={(option: TMedia) => option ? `${option?.code}: ${option?.note}` : ''}
        value={mediaData?.find((v: TMedia) => v.code === media) ?? ''}
        isOptionEqualToValue={(option, value) => option.code === value.code}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label={label ?? "Media items"}
            helperText={helperText ?? ''}
            placeholder={placeholder || "Click or type to search"}
            sx={{ mt: 2, mb: 2 }}
            FormHelperTextProps={{ sx: { color: blue[700] } }}
          />
        )}
        onChange={handleChange}
      />}
      {media && <Box>

      </Box>}
    </Box>
  )
};

export default MediaSelector
