import { Box, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";

function ItemTitleInEdit({
  prefix,
  itemTitle = "",
}: {
  prefix: string;
  itemTitle?: string;
}) {
  return (
    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
      <Typography variant="h3">{prefix}</Typography>
      {!!itemTitle && (
        <Typography
          variant="h4"
          sx={{
            ml: 2,
            fontFamily: "monospace",
            p: 0.5,
            backgroundColor: grey[100],
            borderWidth: 1,
            borderColor: grey[300],
            borderStyle: "solid",
            borderRadius: 2,
          }}
        >
          {itemTitle}
        </Typography>
      )}
    </Box>
  );
}

export default ItemTitleInEdit;
