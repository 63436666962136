import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button
} from "@mui/material";
import WarningIcon from '@mui/icons-material/Warning';
import ErrorIcon from '@mui/icons-material/Error';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';

export interface ConfirmationOptions {
  disabled?: boolean;
  catchOnCancel?: boolean;
  variant?: "danger" | "info" | "error";
  title?: string;
  description?: string | React.ReactNode;
  agreeButtonText?: string;
  cancelButtonText?: string;
  thirdOptionButtonText?: string;
  thirdOptionHandler?: () => void;
}

interface ConfirmationDialogProps extends ConfirmationOptions {
  open: boolean;
  onSubmit: () => void;
  onClose: () => void;
  onExited: () => void;
}

export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  open,
  disabled = false,
  title = '',
  variant = 'info',
  description = '',
  agreeButtonText,
  cancelButtonText,
  thirdOptionButtonText,
  thirdOptionHandler,
  onSubmit,
  onClose,
  onExited
}) => {
  const handleThirdOption = () => {
    if (!thirdOptionHandler) return;
    onClose();
    thirdOptionHandler();
  };
  return (
    <Dialog fullWidth={true} maxWidth="sm" open={open} onClose={onExited}>
      <DialogTitle>
        {variant === 'danger' && <WarningIcon sx={{
          verticalAlign: 'middle',
          marginRight: 2,
          color: "warning.main"
        }} />}
        {variant === 'error' && <ErrorIcon sx={{
          verticalAlign: 'middle',
          marginRight: 2,
          color: "error.main"
        }} />}
        {title}
      </DialogTitle>
      <DialogContent>
        {
          typeof description === 'string' ?
            <DialogContentText>
              {description.split('\n').map((item, key) => {
                return (
                  <span key={key}>
                    {item}
                    <br />
                  </span>
                )
              })}
            </DialogContentText>
            :
            description
        }
      </DialogContent>
      <DialogActions>
        {variant === "danger" && (
          <>
            <Button disabled={disabled} color="primary" onClick={onClose} autoFocus startIcon={<CancelIcon />}>
              {cancelButtonText ?? 'CANCEL'}
            </Button>
            {
              thirdOptionButtonText && thirdOptionHandler && (
                <Button disabled={disabled} color="primary" onClick={handleThirdOption} autoFocus startIcon={<DeleteIcon />}>
                  {thirdOptionButtonText}
                </Button>
              )
            }
            <Button disabled={disabled} color="primary" onClick={onSubmit} startIcon={<CheckCircleOutlineIcon />}>
              {agreeButtonText ?? 'Yes I agree'}
            </Button>
          </>
        )}

        {variant === "info" && (
          <Button disabled={disabled} color="primary" onClick={onSubmit} startIcon={<CheckCircleOutlineIcon />}>
            {agreeButtonText ?? 'OK'}
          </Button>
        )}

        {variant === "error" && (
          <Button disabled={disabled} color="primary" onClick={onSubmit} startIcon={<CheckCircleOutlineIcon />}>
            {agreeButtonText ?? 'OK'}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
