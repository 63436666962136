import React from "react";
import {
  Paper,
  Typography,
  Button,
  Box,
  Stack,
  IconButton,
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  TextField,
  SpeedDial,
  SpeedDialIcon,
  SpeedDialAction,
  Divider,
  InputAdornment,
} from "@mui/material";
import { Routes, Route, Link, useNavigate } from "react-router-dom";
import { TProject } from "../resources/types";
import LanguageSelector from "./LanguageSelector";
import TaskUnitSelector from "./TaskUnitSelector";
import moment from "moment";
import { orange, red } from "@mui/material/colors";
import SaveIcon from "@mui/icons-material/Save";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import EditIcon from "@mui/icons-material/Edit";
import ShareIcon from "@mui/icons-material/Share";
import SettingsIcon from "@mui/icons-material/Settings";
import SettingsTwoToneIcon from "@mui/icons-material/SettingsTwoTone";
import DeleteIcon from "@mui/icons-material/Delete";
import { canonize, formatBytes, interpretDiskSize } from "../libraries/helper";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";
import { useSnackbar } from "../services/CustomSnackbarService";
import RenderMedia from "./RenderMedia";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { TSignedInUserWithSetUser } from "../resources/types";
import { SignedInUserContext } from "../contexts/SignedInUserContext";
import { api } from "../resources/config";

const ProjectEditor = ({
  disabled = false,
  project,
  createNew = false,
  onChange,
  onSave,
  onDelete,
}: {
  disabled?: boolean;
  project: TProject;
  createNew?: boolean;
  onChange: (e: TProject) => void;
  onSave: ({
    code,
    note,
    file,
  }: {
    code: string;
    note: string;
    file?: File;
  }) => void;
  onDelete: () => void;
}) => {
  const signedInUserWithSetUser: TSignedInUserWithSetUser | null =
    React.useContext(SignedInUserContext);
  const [openSpeedDial, setOpenSpeedDial] = React.useState(false);
  // const [selectedFile, setSelectedFile] = React.useState<File | null>(null)
  const {
    code,
    name,
    note,
    url,
    email,
    mediaSizeLimit,
    audioSizeLimit,
    createdDate,
    modifiedDate,
  } = project;
  const codeInputRef = React.useRef<HTMLInputElement>(null);
  const [mediaSizeLimitInBytes, setMediaSizeLimitInBytes] = React.useState(0);
  const [audioSizeLimitInBytes, setAudioSizeLimitInBytes] = React.useState(0);
  const [mediaSizeLimitForEdit, setMediaSizeLimitForEdit] = React.useState("");
  const [audioSizeLimitForEdit, setAudioSizeLimitForEdit] = React.useState("");
  const snackbar = useSnackbar();

  const handleProjectCodeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (createNew) {
      onChange({
        ...project,
        code: canonize(event.target.value),
      });
    } else {
      console.warn(
        `handleProjectCodeChange(), should not allow to change project code`
      );
    }
  };

  const handleProjectCodeClear = () => {
    if (createNew) {
      onChange({
        ...project,
        code: "",
      });
    } else {
      console.warn(
        `handleProjectCodeClear(), should not allow to change project code`
      );
    }
  };

  const handleProjectCodeCheck = async () => {
    console.log(`handleProjectCodeCheck()`);
    if (!createNew || !code) {
      return;
    }
    try {
      const response = await fetch(api.checkProjectData({ code }), {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: `Bearer ${signedInUserWithSetUser?.user?.token}`,
        },
      }).then((res) => res.json());
      if (response.result) {
        console.log(`project "${code}" already exists`);
        setTimeout(() => codeInputRef.current?.focus(), 100);
        snackbar({
          severity: "error",
          message: `"${code}" already exists. Please use a different code.`,
        });
      } else {
        console.log(`project "${code}" does not exist`);
        // snackbar({
        //   severity: 'info',
        //   message: `"${code}" can be used as project code`
        // })
      }
    } catch (err) {
      snackbar({
        severity: "error",
        message: `Failed to check existence of "${code}": ${
          (err as Error).message
        }`,
      });
    }
  };

  const handleProjectNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    onChange({
      ...project,
      name: event.target.value,
    });
  };

  const handleProjectNameClear = () => {
    onChange({
      ...project,
      name: "",
    });
  };

  const handleProjectNoteChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    onChange({
      ...project,
      note: event.target.value,
    });
  };

  const handleProjectNoteClear = () => {
    onChange({
      ...project,
      note: "",
    });
  };

  const handleProjectURLChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    onChange({
      ...project,
      url: event.target.value,
    });
  };

  const handleProjectURLClear = () => {
    onChange({
      ...project,
      url: "",
    });
  };

  const handleProjectEmailChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    onChange({
      ...project,
      email: event.target.value,
    });
  };

  const handleProjectEmailClear = () => {
    onChange({
      ...project,
      email: "",
    });
  };

  React.useEffect(() => {
    setMediaSizeLimitForEdit(
      mediaSizeLimit ? formatBytes(mediaSizeLimit, 2) : ""
    );
    setMediaSizeLimitInBytes(mediaSizeLimit ?? 0);
  }, [mediaSizeLimit]);

  React.useEffect(() => {
    setAudioSizeLimitForEdit(
      audioSizeLimit ? formatBytes(audioSizeLimit, 2) : ""
    );
    setAudioSizeLimitInBytes(audioSizeLimit ?? 0);
  }, [audioSizeLimit]);

  const handleProjectMediaLimitChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setMediaSizeLimitForEdit(event.target.value);
  };

  const interpretProjectMediaLimitChange = () => {
    const limitInBytes = interpretDiskSize(mediaSizeLimitForEdit);
    console.log(
      `interpretProjectMediaLimitChange(): mediaSizeLimitForEdit=${mediaSizeLimitForEdit}, limitInBytes=${limitInBytes}`
    );
    setMediaSizeLimitInBytes(limitInBytes);
    onChange({
      ...project,
      mediaSizeLimit: limitInBytes,
    });
  };

  const handleProjectMediaSizeLimitClear = () => {
    setMediaSizeLimitForEdit("");
    setMediaSizeLimitInBytes(0);
    onChange({
      ...project,
      mediaSizeLimit: 0
    })
  };

  const handleProjectAudioLimitChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setAudioSizeLimitForEdit(event.target.value)
  };

  const interpretProjectAudioLimitChange = () => {
    const limitInBytes = interpretDiskSize(audioSizeLimitForEdit);
    console.log(
      `interpretProjectAudioLimitChange(): audioSizeLimitForEdit=${audioSizeLimitForEdit}, limitInBytes=${limitInBytes}`
    );
    setAudioSizeLimitInBytes(limitInBytes);
    onChange({
      ...project,
      audioSizeLimit: limitInBytes,
    });
  };

  const handleProjectAudioSizeLimitClear = () => {
    setAudioSizeLimitForEdit("");
    setAudioSizeLimitInBytes(0);
    onChange({
      ...project,
      audioSizeLimit: 0
    })
  };

  const detailsIncomplete = React.useMemo(() => !code, [code]);

  const handleSaveProjectChanges = () => {
    if (createNew && detailsIncomplete) {
      return snackbar({
        severity: "warning",
        message: `Please provide a project code`,
      });
    }
    onSave({
      code,
      note,
      // file: selectedFile ?? undefined
    });
  };

  const handleDeleteMedia = () => {
    onDelete();
  };

  const handleOpenSpeedDial = () => {
    setOpenSpeedDial(true);
  };

  const handleCloseSpeedDial = () => {
    setOpenSpeedDial(false);
  };

  return (
    <Stack spacing={2}>
      <Box display="flex" flexDirection="row" alignItems="center">
        <TextField
          disabled={disabled}
          inputRef={codeInputRef}
          fullWidth
          label={"Project code"}
          value={code}
          onChange={handleProjectCodeChange}
          onBlur={handleProjectCodeCheck}
          placeholder={"e.g. pthIntro"}
          helperText={
            createNew
              ? "Choose a unique name (without spaces) for this project. The name cannot be changed afterwards."
              : "Project code becomes read-only once created."
          }
          sx={{ flex: 1 }}
          InputProps={{
            readOnly: !createNew,
            endAdornment: createNew && [
              <InputAdornment position="end" key="clear">
                <IconButton onClick={handleProjectCodeClear}>
                  <ClearIcon />
                </IconButton>
              </InputAdornment>,
              <Divider
                key="divider"
                orientation="vertical"
                variant="middle"
                flexItem
              />,
              <InputAdornment position="end" key="search">
                <IconButton color="primary" onClick={handleProjectCodeCheck}>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>,
            ],
          }}
        />
        {!createNew && (
          <Box display="flex" flexDirection="column" ml={2}>
            <Typography
              variant="caption"
              sx={{ fontSize: "x-small" }}
            >{`Created: ${moment(createdDate).format(
              "YYYY-MM-DD HH:mm:ss"
            )}`}</Typography>
            <Typography
              variant="caption"
              sx={{ fontSize: "x-small" }}
            >{`Updated: ${moment(modifiedDate).format(
              "YYYY-MM-DD HH:mm:ss"
            )}`}</Typography>
          </Box>
        )}
      </Box>
      <TextField
        disabled={disabled}
        label={"Name"}
        value={name}
        onChange={handleProjectNameChange}
        placeholder={`e.g. "Study of dialects"`}
        helperText={
          createNew
            ? `Provide a name for this project`
            : "Update the name of this project"
        }
        InputProps={{
          endAdornment: [
            <InputAdornment position="end" key="clear">
              <IconButton onClick={handleProjectNameClear}>
                <ClearIcon />
              </IconButton>
            </InputAdornment>,
          ],
        }}
      />
      <TextField
        disabled={disabled}
        label={"Note"}
        value={note}
        onChange={handleProjectNoteChange}
        placeholder={`e.g. "To study the math scores on second language learning"`}
        helperText={
          createNew
            ? `Input a brief note to describe this project`
            : "Update the brief note of this project"
        }
        InputProps={{
          endAdornment: [
            <InputAdornment position="end" key="clear">
              <IconButton onClick={handleProjectNoteClear}>
                <ClearIcon />
              </IconButton>
            </InputAdornment>,
          ],
        }}
      />
      <TextField
        disabled={disabled}
        label={"URL"}
        value={url}
        onChange={handleProjectURLChange}
        placeholder={`e.g. "https://project-x.io"`}
        helperText={
          createNew
            ? `Provide a URL to link to this project if available`
            : "Update the URL of this project"
        }
        InputProps={{
          endAdornment: [
            <InputAdornment position="end" key="clear">
              <IconButton onClick={handleProjectURLClear}>
                <ClearIcon />
              </IconButton>
            </InputAdornment>,
          ],
        }}
      />
      <TextField
        disabled={disabled}
        label={"E-mail"}
        value={email}
        onChange={handleProjectEmailChange}
        placeholder={`e.g. "info@project-x.io"`}
        helperText={
          createNew
            ? `Provide a contact e-mail for this project if available`
            : "Update the e-mail of this project"
        }
        InputProps={{
          endAdornment: [
            <InputAdornment position="end" key="clear">
              <IconButton onClick={handleProjectEmailClear}>
                <ClearIcon />
              </IconButton>
            </InputAdornment>,
          ],
        }}
      />
      <TextField
        disabled={disabled}
        label={"Media size limit"}
        value={mediaSizeLimitForEdit}
        onChange={handleProjectMediaLimitChange}
        onBlur={interpretProjectMediaLimitChange}
        placeholder={`e.g. "1G, 2.5G, 500M, etc."`}
        helperText={
          createNew
            ? `Specify the size limit of all media items togethe`
            : `Update the size limit of all media items together`
        }
        InputProps={{
          endAdornment: [
            <InputAdornment position="end" key="clear">
              <IconButton onClick={handleProjectMediaSizeLimitClear}>
                <ClearIcon />
              </IconButton>
            </InputAdornment>,
          ],
        }}
      />
      <TextField
        disabled={disabled}
        label={"Audio data size limit"}
        value={audioSizeLimitForEdit}
        onChange={handleProjectAudioLimitChange}
        onBlur={interpretProjectAudioLimitChange}
        placeholder={`e.g. "1G, 2.5G, 500M, etc."`}
        helperText={
          createNew
            ? `Specify a size limit of collected audio data`
            : `Update the size limit of collected audio data`
        }
        InputProps={{
          endAdornment: [
            <InputAdornment position="end" key="clear">
              <IconButton onClick={handleProjectAudioSizeLimitClear}>
                <ClearIcon />
              </IconButton>
            </InputAdornment>,
          ],
        }}
      />
      <SpeedDial
        ariaLabel={"Save Changes"}
        icon={
          <SpeedDialIcon
            icon={<SettingsIcon />}
            openIcon={<SettingsTwoToneIcon />}
          />
        }
        onClose={handleCloseSpeedDial}
        onOpen={handleOpenSpeedDial}
        open={openSpeedDial}
        direction={"up"}
        hidden={disabled}
        sx={{
          position: "fixed",
          "&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft": {
            bottom: 32,
            right: 32,
          },
          "&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight": {
            top: 32,
            left: 32,
          },
        }}
      >
        <SpeedDialAction
          icon={<SaveIcon />}
          tooltipTitle={"Save Changes"}
          tooltipOpen
          onClick={handleSaveProjectChanges}
        />
        {!createNew && (
          <SpeedDialAction
            icon={<DeleteIcon />}
            tooltipTitle={"Delete"}
            tooltipOpen
            onClick={handleDeleteMedia}
          />
        )}
      </SpeedDial>
    </Stack>
  );
};

export default ProjectEditor;
