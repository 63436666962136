import React from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  Stack,
  DialogActions,
  Button,
} from "@mui/material";
import { useQuery } from "react-query";
import { red } from "@mui/material/colors";
import { api, roles } from "../resources/config";
import { TSignedInUserWithSetUser } from "../resources/types";
import { SignedInUserContext } from "../contexts/SignedInUserContext";
import { useSnackbar } from "../services/CustomSnackbarService";

const ChangePasswordForm = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const signedInUserWithSetUser: TSignedInUserWithSetUser | null =
    React.useContext(SignedInUserContext);
  const [password, setPassword] = React.useState("");
  const [verifyPassword, setVerifyPassword] = React.useState("");
  const [isProcessing, setIsProcessing] = React.useState(false);
  const snackbar = useSnackbar();

  React.useEffect(
    () => {
      if (open) {
        setPassword('')
        setVerifyPassword('')
      }
    },
    [open]
  )

  const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const handleChangeVerifyPassword = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setVerifyPassword(event.target.value);
  };

  const handleConfirmChangePassword = async () => {
    if (!password) {
      return snackbar({
        severity: "error",
        message: `Password cannot be empty`,
      });
    }
    if (password.length < 8) {
      return snackbar({
        severity: "error",
        message: `Password must have at least 8 characters`,
      });
    }
    if (password !== verifyPassword) {
      return snackbar({
        severity: "error",
        message: `The passwords do not match`,
      });
    }
    try {
      setIsProcessing(true);
      const response = await fetch(api.changePassword(), {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: `Bearer ${signedInUserWithSetUser?.user?.token}`,
        },
        body: JSON.stringify({ password }),
      }).then((res) => res.json());
      if (response.username === signedInUserWithSetUser?.user?.username) {
        setIsProcessing(false);
        snackbar({
          severity: "success",
          message: `Password successfully changed`,
        });
        onClose();
      } else {
        setIsProcessing(false);
        throw new Error("Unknown reason");
      }
    } catch (err) {
      setIsProcessing(false);
      snackbar({
        severity: "error",
        message: `Failed to change password: ${(err as Error).message}`,
      });
    }
  };

  const disabled = React.useMemo(() => isProcessing, [isProcessing]);

  return (
    <Dialog fullWidth maxWidth="md" onClose={onClose} open={open}>
      <DialogTitle>{`Change password`}</DialogTitle>
      <DialogContent>
        <Stack sx={{ pt: 2 }} spacing={3}>
          <TextField
            disabled={disabled}
            fullWidth
            variant="standard"
            type="password"
            label="Password"
            value={password}
            onChange={handleChangePassword}
          />
          <TextField
            disabled={disabled}
            fullWidth
            variant="standard"
            type="password"
            label="Verify password"
            value={verifyPassword}
            onChange={handleChangeVerifyPassword}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button disabled={disabled} onClick={onClose}>
          Cancel
        </Button>
        <Button disabled={disabled} onClick={handleConfirmChangePassword}>
          Change password
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default ChangePasswordForm;
