import React from 'react'
import {
  Box, Typography
} from '@mui/material'
import ErrorIcon from '@mui/icons-material/Error';
import { red } from '@mui/material/colors'

const ErrorDisplay = ({ error = '' }) => {
  return (
    <Box sx={{ color: red[600], display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
        <ErrorIcon sx={{ fontSize: '3rem', mr: 1 }} />
        <Typography variant="h6">{error}</Typography>
      </Box>
    </Box>
  )
}
export default ErrorDisplay