import React from "react";
import { Typography, Box, SxProps, Theme } from "@mui/material";

const SkipFlowControlDescription = ({
  prefix,
  lastNthElement,
  equalityCheck,
  targetValue,
  sx = []
}: {
  prefix?: string;
  lastNthElement?: number;
  equalityCheck?: boolean;
  targetValue?: string;
  sx?: SxProps<Theme>;
}) => {
  const ordinalSuffix = React.useMemo(() => {
    switch (lastNthElement) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  }, [lastNthElement]);

  return (
    <Box sx={{ display: "flex", flexDirection: "row", ...sx }}>
      <Typography sx={{ fontStyle: "italic", fontFamily: "serif", mr: 1, whiteSpace: "nowrap" }}>
        {prefix ?? `if`}
      </Typography>
      <Typography>{typeof lastNthElement !== 'undefined' ? `(-${lastNthElement})` : ""}</Typography>
      <Typography
        style={{
          verticalAlign: "super",
          fontSize: "60%",
          fontStyle: "italic",
          fontFamily: "serif",
        }}
      >
        {ordinalSuffix}
      </Typography>
      <Typography sx={{ fontStyle: "italic", fontFamily: "serif", ml: 1 }}>
        item
      </Typography>
      <Typography sx={{ marginX: 1 }}>
        {equalityCheck ? "\uff1d" : "\u2260"}
      </Typography>
      <Typography sx={{ fontStyle: "italic", fontFamily: "serif" }}>
        {"\u201c"}
      </Typography>
      <Typography>{targetValue ? `${targetValue}` : ""}</Typography>
      <Typography sx={{ fontStyle: "italic", fontFamily: "serif" }}>
        {"\u201d"}
      </Typography>
    </Box>
  );
};

export default SkipFlowControlDescription
