import AltRouteIcon from "@mui/icons-material/AltRoute";
import { grey, red } from "@mui/material/colors";

const SkipFlowControlIcon = ({ enabled }: { enabled: boolean }) => {
  return (
    <AltRouteIcon
      fontSize="small"
      sx={{
        color: enabled ? red[900] : grey[500],
        transform: "rotate(90deg)",
      }}
    />
  )
}

export default SkipFlowControlIcon