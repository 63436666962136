import {
  Select,
  MenuItem,
  SelectChangeEvent,
  FormControl,
  InputLabel,
} from "@mui/material";

const equalityCheckOperators = [
  { value: true, symbol: "=" },
  { value: false, symbol: "≠" },
];

const EqualityCheckSelector = ({
  disabled = false,
  label,
  selectedEqualityCheck,
  onChange,
}: {
  disabled?: boolean;
  label?: string;
  selectedEqualityCheck: boolean;
  onChange: (value: boolean) => void;
}) => {
  const handleChange = (event: SelectChangeEvent<string>) => {
    console.log(`event.target.value = ${event.target.value}`)
    onChange(!!equalityCheckOperators.find(v => v.symbol === event.target.value)?.value);

  };

  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel id="equality-check-selector-label">{label ?? ""}</InputLabel>
      <Select
        disabled={disabled}
        labelId="equality-check-selector-label"
        value={equalityCheckOperators.find(v => v.value === selectedEqualityCheck)?.symbol}
        onChange={handleChange}
        label={label ?? ""}
      >
        {equalityCheckOperators.map(v => <MenuItem key={v.symbol} value={v.symbol}>{v.symbol}</MenuItem>)}
      </Select>
    </FormControl>
  );
};

export default EqualityCheckSelector;
