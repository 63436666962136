import React from "react";
import {
    Paper,
    Typography,
    Box,
    List,
    ListItem,
} from "@mui/material";

import ResponsiveAppBar from "../components/AppBar";
import MenuBreadCrumbs from "../components/MenuBreadCrumbs";

function SignUpPage() {
    return (
        <Paper elevation={0}>
            <ResponsiveAppBar />
            <MenuBreadCrumbs items={["home", "signup"]} />
            <Box sx={{ mt: 4, ml: 4, mr: "25%", minWidth: 600, pb: 16 }}>
                <Typography variant="h3" sx={{ mb: 2 }}>Application for Using DOLD</Typography>
                <Box>
                    <Typography variant="h4" sx={{ mt: 4 }}>Eligibility</Typography>
                    <Typography variant="body1" sx={{ mt: 2 }}>DOLD is exclusively for academic research. Staff, postgraduate students, and undergraduate students from the Education University of Hong Kong (EdUHK) and other universities are all eligible to apply for using DOLD for their research. For collaborative research, the application should be submitted by the primary researcher in the research team. Generally, we only accept applications that intend to use DOLD for research in linguistics or language studies. Applications involving EdUHK members for research in other fields will be considered on a case-by-case basis.
                    </Typography>
                </Box>
                <Box>
                    <Typography variant="h4" sx={{ mt: 4 }}>Acknowledgement</Typography>
                    <Typography variant="body1" sx={{ mt: 2 }}>The use of DOLD is free of charge. Please acknowledge the use of DOLD in your research outputs. We also kindly request that you inform us whenever you have any research outputs involving the use of DOLD. Your work will be listed on the DOLD website. This allows us to track and showcase the impact and utilization of DOLD in different research endeavors, and also enhances the visibility of your work.
                    </Typography>
                </Box>
                <Box>
                    <Typography variant="h4" sx={{ mt: 4 }}>How to Apply</Typography>
                    <Typography variant="body1" sx={{ mt: 2 }}>To apply, please fill out and submit an online application form using one of the following links:
                    </Typography>
                    <List
                        sx={{
                            listStyleType: "disc",
                            pl: 4,
                            "& .MuiListItem-root": {
                                display: "list-item",
                            },
                        }}
                    >
                        <ListItem>
                            <a href="https://qfreeaccountssjc1.az1.qualtrics.com/jfe/form/SV_01TKjLdBza10bxc">Application form for staff</a>
                        </ListItem>
                        <ListItem>
                            <a href="https://qfreeaccountssjc1.az1.qualtrics.com/jfe/form/SV_cNNq1XzAMu9hFKS">Application form for postgraduate students</a>
                        </ListItem>
                        <ListItem>
                            <a href="https://qfreeaccountssjc1.az1.qualtrics.com/jfe/form/SV_3DDGjVfAcXPtqsK">Application form for undergraduate students</a>
                        </ListItem>
                    </List>
                    <Typography variant="body1" sx={{ mt: 2 }}>Application results will typically be sent to your email address within 2 working days
                    </Typography>
                </Box >

                <Box>
                    <Typography variant="h4" sx={{ mt: 4 }}>Trial account</Typography>
                    <Typography variant="body1" sx={{ mt: 2 }}>If you are unsure whether DOLD meets the needs of your project, you can request a three-day trial account by emailing us at crlls@eduhk.hk. No application form is required. Note that the trial account will be automatically deactivated after three days without further notice.
                    </Typography>
                </Box>
                <Box>
                    <Typography variant="body1" sx={{ fontStyle: "italic", mt: 4 }}>Disclaimer: Under no circumstance shall EdUHK have any liability to you for any loss or inaccuracy of the data collected via DOLD. EdUHK has the right to end the service of DOLD at any time. Please also read the Disclaimer in the Documentation section of the DOLD website.</Typography>
                </Box>
            </Box>
        </Paper>
    );
}

export default SignUpPage;
