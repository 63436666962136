import React from "react";
import {
  Box,
  Select,
  MenuItem,
  SelectChangeEvent,
  InputAdornment,
  FormControl,
  InputLabel,
  FormControlLabel,
  Checkbox,
  Switch,
  TextField,
  ButtonGroup,
  Button,
  StackTypeMap,
  RadioGroup,
  Radio,
  ToggleButtonGroup,
  ToggleButton,
  IconButton,
  Typography,
} from "@mui/material";
import { blue, orange } from "@mui/material/colors";
import MediaSelector from "./MediaSelector";
import { TMedia } from "../resources/types";
import RenderMedia from "./RenderMedia";
import { api } from "../resources/config";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { TSignedInUserWithSetUser } from "../resources/types";
import { SignedInUserContext } from "../contexts/SignedInUserContext";

type TSpecialValues = {
  label: string;
  value: string;
  useInputValue?: boolean;
};

const TaskUnitJsPsychPluginConfigurator = ({
  disabled = false,
  plugInPrototype,
  plugInConfig,
  onChange,
}: {
  disabled?: boolean;
  plugInPrototype: any;
  plugInConfig: any;
  onChange?: (attribute: string, value: any) => void;
}) => {
  const signedInUserWithSetUser: TSignedInUserWithSetUser | null =
    React.useContext(SignedInUserContext);
  const projectId = React.useMemo(
    () =>
      signedInUserWithSetUser?.projects?.find(
        (v) => v.code === signedInUserWithSetUser?.projectCodeInUse
      )?._id,
    [
      signedInUserWithSetUser?.projectCodeInUse,
      signedInUserWithSetUser?.projects,
    ]
  );

  const [currentMedia, setCurrentMedia] = React.useState<TMedia | null>(null);

  React.useEffect(() => {
    const getMedia = async (code: string) => {
      try {
        const media = await fetch(
          api.getMediaData({ code: plugInConfig.stimulusCode, projectId }),
          {
            method: "GET",
            credentials: "include",
            headers: {
              "Content-Type": "application/json;charset=UTF-8",
              Authorization: `Bearer ${signedInUserWithSetUser?.user?.token}`,
            },
          }
        ).then((res) => res.json());
        media && setCurrentMedia(media);
      } catch (err) {
        console.error(err);
      }
    };
    if (plugInConfig.stimulusCode) {
      console.log(`stimulus code = ${plugInConfig.stimulusCode}`);
      getMedia(plugInConfig.stimulusCode);
    }
  }, [plugInConfig, projectId]);

  const attributeKeys = React.useMemo(
    () => Object.keys(plugInPrototype.attributes),
    [plugInPrototype]
  );

  const handleBooleanChange = (
    attribute: string,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    console.log(`handleBooleanChange: ${attribute}`);
    console.log(event);
    onChange && onChange(attribute, event.target.checked);
  };

  const handleTextChange = (
    attribute: string,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    console.log(`handleTextChange: ${attribute}`);
    console.log(event);
    onChange && onChange(attribute, event.target.value);
  };

  const handleTextArrayChange = (
    attribute: string,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    console.log(`handleTextArrayChange: ${attribute}`);
    console.log(event);
    onChange && onChange(attribute, event.target.value.split("\n"));
  };

  const handleNumberChange = (
    attribute: string,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    console.log(`handleNumberChange: ${attribute}`);
    console.log(event);
    onChange && onChange(attribute, parseInt(event.target.value));
  };

  const handleQuestionPromptChange = (
    attribute: string,
    questionIndex: number,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    console.log(`handleQuestionPromptChange: ${attribute}`);
    console.log(event);
    onChange &&
      onChange(attribute, [
        ...plugInConfig[attribute].slice(0, questionIndex),
        {
          ...plugInConfig[attribute][questionIndex],
          prompt: event.target.value,
        },
        ...plugInConfig[attribute].slice(questionIndex + 1),
      ]);
  };

  const handleQuestionRequiredChange = (
    attribute: string,
    questionIndex: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    console.log(`handleQuestionRequiredChange: ${attribute}`);
    console.log(event);
    onChange &&
      onChange(attribute, [
        ...plugInConfig[attribute].slice(0, questionIndex),
        {
          ...plugInConfig[attribute][questionIndex],
          required: event.target.checked,
        },
        ...plugInConfig[attribute].slice(questionIndex + 1),
      ]);
  };

  const handleQuestionRowsChange = (
    attribute: string,
    questionIndex: number,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    console.log(`handleQuestionRowsChange: ${attribute}`);
    console.log(event);
    onChange &&
      onChange(attribute, [
        ...plugInConfig[attribute].slice(0, questionIndex),
        {
          ...plugInConfig[attribute][questionIndex],
          rows: parseInt(event.target.value),
        },
        ...plugInConfig[attribute].slice(questionIndex + 1),
      ]);
  };

  const handleQuestionOptionsChange = (
    attribute: string,
    questionIndex: number,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    console.log(`handleQuestionOptionsChange: ${attribute}`);
    console.log(event);
    onChange &&
      onChange(attribute, [
        ...plugInConfig[attribute].slice(0, questionIndex),
        {
          ...plugInConfig[attribute][questionIndex],
          options: event.target.value.split("\n"),
        },
        ...plugInConfig[attribute].slice(questionIndex + 1),
      ]);
  };

  const handleQuestionAdd = (attribute: string) => {
    console.log(`handleQuestionAdd: ${attribute}`);
    onChange &&
      onChange(attribute, [
        ...plugInConfig[attribute],
        {
          prompt: "",
          rows: 1,
          required: true,
        },
      ]);
  };

  const handleQuestionDelete = (attribute: string, questionIndex: number) => {
    console.log(`handleQuestionDelete: ${attribute}`);
    onChange &&
      onChange(attribute, [
        ...plugInConfig[attribute].slice(0, questionIndex),
        ...plugInConfig[attribute].slice(questionIndex + 1),
      ]);
  };

  const handleMediaChange = (attribute: string, media: TMedia | null) => {
    console.log(`handleMediaChange: ${attribute} / ${JSON.stringify(media)}`);
    if (media) {
      setCurrentMedia(media);
    } else {
      setCurrentMedia(null);
    }
    onChange && onChange(attribute, media);
  };

  const handleToggleButtonChange = (
    attribute: string,
    event: React.MouseEvent<HTMLElement>,
    value: string
  ) => {
    const attributeProperties = plugInPrototype.attributes?.[attribute];
    const specialValueForInputValue = attributeProperties.specialValues?.find(
      (v: TSpecialValues) => v.useInputValue
    )?.value;

    console.log(`handleToggleButtonChange: ${attribute}, new value = ${value}`);
    console.log(event);
    console.log(`handleToggleButtonChange: ${attribute}, new value = ${value}`);
    if (value === specialValueForInputValue) {
      onChange && onChange(attribute, []);
    } else {
      onChange && onChange(attribute, value);
    }
  };

  return (
    <Box>
      {attributeKeys.map((attributeKey) => {
        const attributeProperties = plugInPrototype.attributes?.[attributeKey];
        if (!attributeProperties) {
          return null;
        }

        let visibleByMediaType = true;
        if (
          attributeProperties.supportedMediaTypes &&
          currentMedia?.mimeTypeMajor &&
          !attributeProperties.supportedMediaTypes.includes(
            currentMedia?.mimeTypeMajor
          )
        ) {
          visibleByMediaType = false;
        } else if (attributeProperties.supportedMediaTypes && !currentMedia) {
          visibleByMediaType = false;
        }

        if (
          attributeProperties.type === "boolean" &&
          !attributeProperties.hidden &&
          visibleByMediaType
        ) {
          return (
            <FormControl
              key={attributeKey}
              fullWidth
              variant="outlined"
              sx={{ alignItems: "flex-start" }}
            >
              <TextField
                fullWidth
                label={attributeProperties.label ?? attributeKey}
                placeholder={attributeProperties.placeHolder ?? ""}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Switch
                        checked={plugInConfig[attributeKey]}
                        onChange={(event) =>
                          handleBooleanChange(attributeKey, event)
                        }
                      />
                    </InputAdornment>
                  ),
                  readOnly: true,
                }}
                sx={{
                  mt: 2,
                  mb: 2,
                }}
                helperText={attributeProperties.description ?? ""}
                FormHelperTextProps={{ sx: { color: blue[700] } }}
              />
            </FormControl>
          );
        } else if (
          attributeProperties.type === "string[]" &&
          !attributeProperties.hidden &&
          visibleByMediaType
        ) {
          const specialValueForInputValue =
            attributeProperties.specialValues?.find(
              (v: TSpecialValues) => v.useInputValue
            )?.value;
          const useInputValue = !attributeProperties.specialValues
            ?.filter((v: TSpecialValues) => !v.useInputValue)
            ?.map((v: TSpecialValues) => v.value)
            ?.includes(plugInConfig[attributeKey]);
          return (
            <TextField
              key={attributeKey}
              fullWidth
              multiline={true}
              minRows={2}
              maxRows={4}
              placeholder={useInputValue ? attributeProperties.placeHolder : ""}
              label={attributeProperties.label ?? attributeKey}
              helperText={attributeProperties.description}
              value={
                typeof plugInConfig[attributeKey]?.join === "function"
                  ? plugInConfig[attributeKey]?.join("\n")
                  : ""
              }
              onChange={(event) => handleTextArrayChange(attributeKey, event)}
              sx={{ mt: 2, mb: 2 }}
              FormHelperTextProps={{ sx: { color: blue[700] } }}
              InputProps={{
                startAdornment: attributeProperties.specialValues && (
                  <InputAdornment position="start">
                    <ToggleButtonGroup
                      exclusive
                      onChange={(event, value) =>
                        handleToggleButtonChange(attributeKey, event, value)
                      }
                      value={
                        useInputValue
                          ? specialValueForInputValue
                          : plugInConfig[attributeKey]
                      }
                      sx={{ pr: 4 }}
                    >
                      {attributeProperties.specialValues?.map(
                        (v: TSpecialValues) => (
                          <ToggleButton key={v.value} value={v.value}>
                            {v.label}
                          </ToggleButton>
                        )
                      )}
                    </ToggleButtonGroup>
                  </InputAdornment>
                ),
                readOnly: !useInputValue,
              }}
            />
          );
        } else if (
          attributeProperties.type === "questions" &&
          !attributeProperties.hidden &&
          visibleByMediaType
        ) {
          return (
            <Box
              key={attributeKey}
              sx={{
                mt: 2,
                padding: 1,
                borderStyle: "solid",
                borderWidth: 1,
                borderColor: blue[300],
              }}
            >
              <InputLabel>
                {attributeProperties.label ?? attributeKey}
              </InputLabel>
              {plugInConfig[attributeKey]?.map(
                (q: any, questionIndex: number) => (
                  <Box
                    key={`question-${questionIndex}`}
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                  >
                    <Typography
                      sx={{ minWidth: "2rem", textAlign: "center" }}
                    >{`Q${questionIndex}`}</Typography>
                    <TextField
                      label="Enter a question"
                      fullWidth
                      helperText={attributeProperties.description ?? ""}
                      placeholder={attributeProperties.placeHolder ?? ""}
                      value={q.prompt ?? ""}
                      onChange={(event) =>
                        handleQuestionPromptChange(
                          attributeKey,
                          questionIndex,
                          event
                        )
                      }
                      sx={{
                        flex: 4,
                        margin: 2,
                        // mt: 2,
                        // mb: 2,
                        // mr: 2
                      }}
                      FormHelperTextProps={{ sx: { color: blue[700] } }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            Required?
                            <Checkbox
                              checked={!!q.required}
                              onChange={(event) =>
                                handleQuestionRequiredChange(
                                  attributeKey,
                                  questionIndex,
                                  event
                                )
                              }
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <TextField
                      label="Rows"
                      fullWidth
                      type="number"
                      value={q.rows ?? ""}
                      InputProps={{ inputProps: { min: 1, max: 10 } }}
                      onChange={(event) =>
                        handleQuestionRowsChange(
                          attributeKey,
                          questionIndex,
                          event
                        )
                      }
                      sx={{ width: "8ch" }}
                    />
                    <IconButton
                      onClick={() =>
                        handleQuestionDelete(attributeKey, questionIndex)
                      }
                      sx={{ ml: 1 }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                )
              )}
              <Button
                startIcon={<AddIcon />}
                onClick={() => handleQuestionAdd(attributeKey)}
              >
                Add a question
              </Button>
            </Box>
          );
        } else if (
          attributeProperties.type === "multichoice" &&
          !attributeProperties.hidden &&
          visibleByMediaType
        ) {
          return (
            <Box
              key={attributeKey}
              sx={{
                mt: 2,
                padding: 1,
                borderStyle: "solid",
                borderWidth: 1,
                borderColor: blue[300],
              }}
            >
              <InputLabel>
                {attributeProperties.label ?? attributeKey}
              </InputLabel>
              {plugInConfig[attributeKey]?.map(
                (q: any, questionIndex: number) => (
                  <Box
                    key={`question-${questionIndex}`}
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                  >
                    <Typography
                      sx={{ minWidth: "2rem", textAlign: "center" }}
                    >{`Q${questionIndex}`}</Typography>
                    <TextField
                      label="Prompt"
                      fullWidth
                      helperText={attributeProperties.description ?? ""}
                      placeholder={attributeProperties.placeHolder ?? ""}
                      value={q.prompt ?? ""}
                      onChange={(event) =>
                        handleQuestionPromptChange(
                          attributeKey,
                          questionIndex,
                          event
                        )
                      }
                      sx={{
                        flex: 4,
                        margin: 2,
                        // mt: 2,
                        // mb: 2,
                        // mr: 2
                      }}
                      FormHelperTextProps={{ sx: { color: blue[700] } }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            Required?
                            <Checkbox
                              checked={!!q.required}
                              onChange={(event) =>
                                handleQuestionRequiredChange(
                                  attributeKey,
                                  questionIndex,
                                  event
                                )
                              }
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <TextField
                      label="Options"
                      placeholder="One option on one line"
                      fullWidth
                      multiline
                      maxRows={4}
                      value={q.options ? q.options.join("\n") : ""}
                      onChange={(event) =>
                        handleQuestionOptionsChange(
                          attributeKey,
                          questionIndex,
                          event
                        )
                      }
                      sx={{ width: "24ch" }}
                    />
                    <IconButton
                      onClick={() =>
                        handleQuestionDelete(attributeKey, questionIndex)
                      }
                      sx={{ ml: 1 }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                )
              )}
              <Button
                startIcon={<AddIcon />}
                onClick={() => handleQuestionAdd(attributeKey)}
              >
                Add a question
              </Button>
            </Box>
          );
        } else if (
          attributeProperties.type === "string" &&
          !attributeProperties.hidden &&
          visibleByMediaType
        ) {
          return (
            <TextField
              key={attributeKey}
              fullWidth
              label={attributeProperties.label ?? attributeKey}
              helperText={attributeProperties.description ?? ""}
              placeholder={attributeProperties.placeHolder ?? ""}
              value={plugInConfig[attributeKey]}
              onChange={(event) => handleTextChange(attributeKey, event)}
              sx={{
                mt: 2,
                mb: 2,
              }}
              FormHelperTextProps={{ sx: { color: blue[700] } }}
            />
          );
        } else if (
          attributeProperties.type === "number" &&
          !attributeProperties.hidden &&
          visibleByMediaType
        ) {
          return (
            <TextField
              key={attributeKey}
              fullWidth
              type="number"
              InputProps={{
                inputProps: {
                  min: 0,
                },
                endAdornment: plugInPrototype.attributes?.[attributeKey]
                  .unit ? (
                  <InputAdornment position="end">
                    {plugInPrototype.attributes?.[attributeKey].unit}
                  </InputAdornment>
                ) : undefined,
              }}
              label={attributeProperties.label ?? attributeKey}
              value={plugInConfig[attributeKey]}
              onChange={(event) => handleNumberChange(attributeKey, event)}
              sx={{
                mt: 2,
                mb: 2,
              }}
              helperText={attributeProperties.description ?? ""}
              placeholder={attributeProperties.placeHolder ?? ""}
              FormHelperTextProps={{ sx: { color: blue[700] } }}
            />
          );
        } else if (
          ["media", "media[]"].includes(attributeProperties.type) &&
          !attributeProperties.hidden &&
          visibleByMediaType
        ) {
          return (
            <React.Fragment key={attributeKey}>
              {attributeProperties.mediaType?.includes("audio") && (
                <FormControl
                  key={attributeKey}
                  fullWidth
                  variant="outlined"
                  sx={{ alignItems: "flex-start" }}
                >
                  <TextField
                    fullWidth
                    label={"Use last audio response"}
                    placeholder={
                      plugInConfig[attributeKey] === "last_response"
                        ? "Using last audio response as stimulus"
                        : "Not using last audio response. Please choose stimulus below."
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Switch
                            checked={
                              plugInConfig[attributeKey] === "last_response"
                            }
                            onChange={(event) => {
                              if (event.target.checked) {
                                setCurrentMedia(null);
                                onChange &&
                                  onChange(attributeKey, "last_response");
                              } else {
                                onChange && onChange(attributeKey, null);
                              }
                            }}
                          />
                        </InputAdornment>
                      ),
                      readOnly: true,
                    }}
                    sx={{
                      mt: 2,
                      mb: 2,
                    }}
                    inputProps={{
                      style: {
                        color: blue[900],
                      },
                    }}
                  />
                </FormControl>
              )}
              <MediaSelector
                disabled={plugInConfig[attributeKey] === "last_response"}
                label={attributeProperties.label ?? attributeKey}
                helperText={attributeProperties.description ?? ""}
                placeholder={attributeProperties.placeHolder ?? ""}
                mediaType={attributeProperties.mediaType}
                media={
                  attributeProperties.mediaKey
                    ? plugInConfig[attributeProperties.mediaKey]
                    : plugInConfig[attributeKey]
                }
                onChange={(media: TMedia | null) =>
                  handleMediaChange(attributeKey, media)
                }
              />
              {currentMedia && (
                <RenderMedia
                  key={currentMedia.code}
                  size="medium"
                  type={currentMedia.mimeTypeMajor}
                  filename={currentMedia.filename}
                  url={currentMedia.url}
                  playable={true}
                />
              )}
            </React.Fragment>
          );
        } else {
          // return <Box>{attributeKey}</Box>
          return null;
        }
      })}
      <FormControl variant="outlined">
        <pre style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
          {JSON.stringify(plugInConfig, null, 2)}
        </pre>
      </FormControl>
    </Box>
  );
};

export default TaskUnitJsPsychPluginConfigurator;
