import React from "react";
import {
  Paper,
  Typography,
  Box,
  Stack,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Chip,
  SpeedDial,
  SpeedDialIcon,
  SpeedDialAction,
  TextField,
  InputAdornment,
  Divider,
} from "@mui/material";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { Routes, Route, Link, useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import RefreshIcon from "@mui/icons-material/Refresh";
import DownloadIcon from "@mui/icons-material/Download";
import EditIcon from "@mui/icons-material/Edit";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import MicIcon from "@mui/icons-material/Mic";
import AssignmentIcon from "@mui/icons-material/Assignment";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import SettingsIcon from "@mui/icons-material/Settings";
import SettingsTwoToneIcon from "@mui/icons-material/SettingsTwoTone";
import DataCount from "../components/DataCount";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import moment from "moment";
import WaveSurfer from "wavesurfer.js";
import { blue } from "@mui/material/colors";
import { Fade } from "react-awesome-reveal";
import { api } from "../resources/config";
import { TProject } from "../resources/types";
import CurtainWithProgress from "../components/CurtainWithProgress";
import TaskUnitCard from "../components/TaskUnitCard";
import { useConfirmation } from "../services/ConfirmationService";
import { useSnackbar } from "../services/CustomSnackbarService";
import ProjectEditor from "../components/ProjectEditor";
import { useTable, useSortBy } from "react-table";
import { matchKeywords } from "../libraries/helper";
import ResponsiveAppBar from "../components/AppBar";
import MenuBreadCrumbs from "../components/MenuBreadCrumbs";
import { TSignedInUserWithSetUser } from "../resources/types";
import { SignedInUserContext } from "../contexts/SignedInUserContext";
import ErrorDisplay from "../components/ErrorDisplay";
import ItemTitleInEdit from "../components/ItemTitleInEdit";

function ProjectPage() {
  const signedInUserWithSetUser: TSignedInUserWithSetUser | null =
    React.useContext(SignedInUserContext);
  const [projectForEdit, setProjectForEdit] = React.useState<TProject | null>(
    null
  );
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [createNew, setCreateNew] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(5);
  const [sortColumn, setSortColumn] = React.useState("");
  const [isSortedDesc, setIsSortedDesc] = React.useState<boolean | undefined>(
    false
  );
  const [projectDataFilter, setProjectDataFilter] = React.useState("");
  const [search, setSearch] = React.useState("");
  const searchTextField = React.useRef<HTMLInputElement>(null);
  const snackbar = useSnackbar();
  const confirm = useConfirmation();

  const {
    isLoading,
    isFetching,
    isError,
    error,
    data: projectData,
    refetch: refetchProjectData,
  } = useQuery(
    ["project-data", currentPage, pageSize, sortColumn, isSortedDesc, search],
    () =>
      fetch(
        api.getProjects({
          page: currentPage.toString(),
          limit: pageSize.toString(),
          sortColumn: sortColumn || "",
          isSortedDesc: isSortedDesc?.toString() || "false",
          search,
        }),
        {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: `Bearer ${signedInUserWithSetUser?.user?.token}`,
          },
        }
      ).then((res) => {
        console.log(`status = ${res.status}`);
        if (res.status === 401) {
          throw new Error("Unauthorized");
        }
        return res.json();
      }),
    {
      enabled: !!signedInUserWithSetUser?.user?.username,
    }
  );

  React.useEffect(() => {
    if (isError) {
      console.log(`isError: ${error}`);
      if ((error as Error).message === "Unauthorized") {
        signedInUserWithSetUser?.setUser(null);
      }
    }
  }, [isError, error, signedInUserWithSetUser]);

  React.useEffect(() => {
    setTimeout(() => searchTextField.current?.focus(), 100);
  }, [projectData]);

  const handleEdit = React.useCallback(
    (code: string) => {
      console.log(`handleEdit(): code = ${code}`);
      const project = projectData?.docs?.find((v: TProject) => v.code === code);
      if (project) {
        setCreateNew(false);
        setProjectForEdit({ ...project });
      }
    },
    [projectData?.docs]
  );

  const columns = React.useMemo(() => {
    return [
      {
        Header: "Project ID",
        accessor: "code",
        width: "10%",
        // Cell: ({ row, value }: { row: any, value: any }) => {
        //   const type = row.original.mimeTypeMajor
        //   return <Stack direction="row" spacing={2} justifyContent={'space-between'}>{value}<TypeIcon type={type} /></Stack>
        // }
      },
      {
        Header: "Name",
        accessor: "name",
        width: "20%",
      },
      {
        Header: "Note",
        accessor: "note",
        width: "20%",
      },
      {
        Header: "URL",
        accessor: "url",
        Cell: ({ row, value }: { row: any; value: any }) => {
          return (
            value && (
              <Stack
                direction="row"
                spacing={2}
                alignItems="center"
                justifyContent={"space-between"}
              >
                <a href={value}>{value}</a>
                <IconButton
                  onClick={() => {
                    window.open(value, "_blank");
                  }}
                >
                  <OpenInNewIcon />
                </IconButton>
              </Stack>
            )
          );
        },
        width: "20%",
      },
      {
        Header: "E-mail",
        accessor: "email",
        width: "15%",
      },
      {
        Header: "Created",
        accessor: "createddDate",
        Cell: ({ row, value }: { row: any; value: any }) => {
          return moment(value).format("YYYY-MM-DD HH:mm:ss");
        },
        width: "15%",
      },
      {
        Header: "Modified",
        accessor: "modifiedDate",
        Cell: ({ row, value }: { row: any; value: any }) => {
          return moment(value).format("YYYY-MM-DD HH:mm:ss");
        },
        width: "15%",
      },
      {
        Header: "",
        id: "edit",
        width: "5%",
        Cell: ({ row, value }: { row: any; value: string }) => {
          const code = row.original.code;
          return code ? (
            <IconButton onClick={() => handleEdit(code)}>
              <EditIcon />
            </IconButton>
          ) : null;
        },
      },
    ];
  }, [handleEdit]);

  const handleSort = (newSortColumn: string, newIsSortedDesc?: boolean) => {
    console.log(
      `in handleSort(),newSortColumn=${newSortColumn}, newIsSortedDesc=${newIsSortedDesc} `
    );
    setSortColumn(newSortColumn);
    setIsSortedDesc(newIsSortedDesc);
  };

  const handleCreateNewProject = () => {
    console.log(`handleCreateNewProject`);
    setCreateNew(true);
    setProjectForEdit({
      code: "",
      name: "",
      note: "",
      url: "",
      email: "",
      createdDate: new Date(),
      modifiedDate: new Date(),
    });
  };

  const handleProjectChange = (project: TProject) => {
    // console.log(`handleProjectChange(), project = ${JSON.stringify(project)}`)
    setProjectForEdit(project);
  };

  const handleProjectDelete = () => {
    if (!projectForEdit?.code) {
      return;
    }
    const { code } = projectForEdit;
    // console.log(`handleProjectDelete() code=${code}`)
    confirm({
      variant: "danger",
      title: `Delete project "${code}"`,
      description: `Are you sure to delete project "${code}"?`,
      cancelButtonText: "No",
      agreeButtonText: "Yes, go ahead to delete",
      catchOnCancel: true,
    })
      .then(() => {
        console.log(`Go ahead to delete ${code}`);
        fetch(api.deleteProject({ code }), {
          method: "DELETE",
          credentials: "include",
          headers: {
            // "Content-Type": "application/json;charset=UTF-8",
            Authorization: `Bearer ${signedInUserWithSetUser?.user?.token}`,
          },
        })
          .then((res) => res.json())
          .then((json) => {
            snackbar({
              severity: "success",
              message: `Media "${code}" is deleted successfully`,
            });
            setProjectForEdit(null);
            refetchProjectData();
          })
          .catch((error) => {
            snackbar({
              severity: "error",
              message: `Deletion of project "${code}" failed: ${(
                error as Error
              )?.toString()}`,
            });
          });
      })
      .catch(() => {
        snackbar({
          severity: "info",
          message: `Deletion of project "${code}" cancelled`,
        });
      });
  };

  const handleProjectSave = async () => {
    console.log(`handleSaveEdited`);
    if (!projectForEdit) {
      return;
    }
    setIsProcessing(true);
    fetch(createNew ? api.createProject() : api.updateProject(), {
      method: createNew ? "PUT" : "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${signedInUserWithSetUser?.user?.token}`,
      },
      body: JSON.stringify(projectForEdit),
    })
      .then((res) => res.json())
      .then((json) => {
        console.log(json);
        if (!createNew) {
          if (!json || !json[0] || json[0].code !== projectForEdit.code) {
            throw new Error(
              `Failed to save project "${projectForEdit.code}" ${
                json.message ?? ""
              }`
            );
          } else {
            setIsProcessing(false);
            snackbar({
              severity: "success",
              message: `Saving project "${projectForEdit.code}" successfully`,
            });
            setProjectForEdit(null);
          }
        } else {
          if (!json || json.code !== projectForEdit.code) {
            throw new Error(
              `Failed to create project "${projectForEdit.code}" ${
                json.message ?? ""
              }`
            );
          } else {
            setIsProcessing(false);
            snackbar({
              severity: "success",
              message: `Creating new project "${projectForEdit.code}" successfully`,
            });
            setProjectForEdit(null);
          }
        }
        refetchProjectData();
      })
      .catch((err) => {
        setIsProcessing(false);
        confirm({
          variant: "error",
          title: `Error saving project "${projectForEdit.code}"`,
          description:
            err?.toString() ?? "Error saving project. Please try again later.",
          agreeButtonText: "OK",
        });
      })
      .finally(() => {
        setIsProcessing(false);
      });
  };

  const handleProjectDataFilterChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setProjectDataFilter(event.target.value);
  };

  const handlePressEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  const handleProjectDataFilterClear = () => {
    setProjectDataFilter("");
    setCurrentPage(1);
    setSearch("");
  };

  const handleSearch = () => {
    setCurrentPage(1);
    setSearch(projectDataFilter);
  };

  const filteredProjectData = React.useMemo(
    () => {
      // const mediaDataFilterKeywords = projectDataFilter.trim().split(/\s+/)
      // const filteredProjectData = projectData?.docs.filter((v: TProject) => !projectDataFilter ||
      //   matchKeywords([v.code, v.note, v.mimeTypeMajor], mediaDataFilterKeywords))
      // return {
      //   ...projectData,
      //   docs: filteredProjectData
      // }
      return {
        ...projectData,
      };
    },
    // [projectData, projectDataFilter]
    [projectData]
  );

  const disabled = React.useMemo(
    () => isLoading || isFetching || isProcessing,
    [isLoading, isFetching, isProcessing]
  );

  return (
    <Paper elevation={0} sx={{ height: "100vh", overflow: "scroll" }}>
      <ResponsiveAppBar />
      <MenuBreadCrumbs items={["home", "project"]} />
      <Box>
        <Box sx={{ mt: 4, ml: 4 }}>
          {projectForEdit ? (
            <ItemTitleInEdit
              prefix={createNew ? `Create new project` : `Edit project`}
              itemTitle={projectForEdit.code}
            />
          ) : (
            <Typography variant="h3">Projects</Typography>
          )}
        </Box>
        {isError && (
          <Box sx={{ mt: 4, ml: 4, mr: 4, p: 4 }}>
            <ErrorDisplay error={(error as Error).toString()} />
          </Box>
        )}
        {!isError && !projectForEdit && (
          <Box
            display={"flex"}
            flexDirection="row"
            justifyContent={"space-between"}
            sx={{ ml: 4 }}
          >
            <Button startIcon={<AddIcon />} onClick={handleCreateNewProject}>
              Create a new project
            </Button>
          </Box>
        )}

        {!isError && projectData && !projectForEdit && (
          <Box sx={{ mt: 4, ml: 4, mr: 4 }}>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="flex-start"
              justifyContent="space-between"
              sx={{ mb: 2 }}
            >
              <Typography variant="body1">
                Browse the projects, or search with keywords.
              </Typography>
            </Box>
            <TextField
              fullWidth
              variant="outlined"
              margin="none"
              size="small"
              inputRef={searchTextField}
              value={projectDataFilter}
              onChange={handleProjectDataFilterChange}
              onKeyPress={handlePressEnter}
              placeholder={`e.g. "cantonese"`}
              helperText={"Input some keywords to begin searching."}
              InputProps={{
                endAdornment: [
                  <InputAdornment position="end" key="clear">
                    <IconButton onClick={handleProjectDataFilterClear}>
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>,
                  <Divider
                    key="divider"
                    orientation="vertical"
                    variant="middle"
                    flexItem
                  />,
                  <InputAdornment position="end" key="search">
                    <IconButton color="primary" onClick={handleSearch}>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>,
                ],
              }}
            />
          </Box>
        )}

        {!isError &&
          projectData &&
          !projectForEdit &&
          projectData.totalDocs === 0 && (
            <Box sx={{ mt: 4, ml: 4, mr: 4 }}>
              <Typography variant="body1">No projects found</Typography>
            </Box>
          )}

        {!isError &&
          projectData &&
          !projectForEdit &&
          projectData.totalDocs > 0 && (
            <Box sx={{ mt: 4, ml: 4, mr: 4 }}>
              <Box
                display="flex"
                flexDirection="row"
                alignItems="flex-start"
                justifyContent="space-between"
              >
                <Box display="flex" flexDirection="row" alignItems="center">
                  <Typography variant="caption" sx={{ mr: 4 }}>
                    {`Total: ${projectData.totalDocs} records.` +
                      ` Record #${
                        (projectData.page - 1) * projectData.limit + 1
                      } to #${
                        (projectData.page - 1) * projectData.limit +
                        projectData.docs.length
                      }`}
                  </Typography>
                </Box>
                <Box display="flex" flexDirection="row" alignItems="flex-end">
                  <Button
                    startIcon={<ArrowBackIcon />}
                    disabled={!projectData.hasPrevPage}
                    onClick={() => setCurrentPage(projectData.prevPage)}
                  >
                    Previous
                  </Button>
                  <Button
                    startIcon={<ArrowForwardIcon />}
                    disabled={!projectData.hasNextPage}
                    onClick={() => setCurrentPage(projectData.nextPage)}
                  >
                    Next
                  </Button>
                </Box>
              </Box>
              <ProjectDataTable
                columns={columns}
                data={filteredProjectData.docs}
                sortColumn={sortColumn}
                isSortedDesc={isSortedDesc}
                onSort={handleSort}
              />
            </Box>
          )}
        {!isError && projectData && projectForEdit && (
          <Box
            display={"flex"}
            flexDirection="column"
            justifyContent={"space-between"}
            sx={{ mr: 24 }}
          >
            <Box sx={{ ml: 4 }}>
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                mb={4}
              >
                <Button
                  startIcon={<ArrowBackIosIcon />}
                  onClick={() => setProjectForEdit(null)}
                >
                  Back to project list
                </Button>
              </Box>
              <ProjectEditor
                disabled={disabled}
                createNew={createNew}
                project={projectForEdit}
                onSave={handleProjectSave}
                onChange={handleProjectChange}
                onDelete={handleProjectDelete}
              />
            </Box>
          </Box>
        )}
      </Box>
      {disabled && !error && <CurtainWithProgress open={disabled} />}
    </Paper>
  );
}

const ProjectDataTable = ({
  columns,
  data,
  onSort,
  sortColumn,
  isSortedDesc,
}: {
  columns: any;
  data: any;
  onSort: (sortColumn: string, isSortedDesc?: boolean) => void;
  sortColumn: string;
  isSortedDesc: boolean | undefined;
}) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });

  return (
    <Table {...getTableProps()}>
      <TableHead>
        {headerGroups.map((headerGroup) => (
          <TableRow {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column: any) => {
              // console.log(`Current Header, column.id = ${column.id}`)
              // console.log(`Current Header, column.isSorted = ${column.isSorted}`)
              // console.log(`Current Header, column.isSortedDesc = ${column.isSortedDesc}`)
              return (
                <TableCell
                  {...column.getHeaderProps()}
                  onClick={(event) => {
                    // console.log("Header clicked, event = %o", event)
                    // console.log(`Header clicked, column = %o`, column)
                    // console.log(`Header clicked, column.id = ${column.id}`)
                    // console.log(`Header clicked, column.isSorted = ${column.isSorted}`)
                    // console.log(`Header clicked, column.isSortedDesc = ${column.isSortedDesc}`)
                    // if (!column.isSorted) {
                    //   // pass on next values, not current values
                    //   onSort(column.id, typeof column.isSortedDesc === 'undefined' ? false : !column.isSortedDesc)
                    // } else {
                    //   onSort('')
                    // }
                    if (column.id === sortColumn && isSortedDesc) {
                      onSort("");
                    } else if (
                      column.id === sortColumn &&
                      isSortedDesc === false
                    ) {
                      onSort(column.id, true);
                    } else {
                      onSort(column.id, false);
                    }
                  }}
                  sx={{ width: column.width }}
                >
                  {column.render("Header")}
                  <span>
                    {column.id === sortColumn
                      ? isSortedDesc
                        ? " 🔽"
                        : " 🔼"
                      : ""}
                  </span>
                </TableCell>
              );
            })}
          </TableRow>
        ))}
      </TableHead>
      <TableBody {...getTableBodyProps()}>
        {rows.map((row: any, i: number) => {
          prepareRow(row);
          return (
            <TableRow {...row.getRowProps()}>
              {row.cells.map((cell: any) => (
                <TableCell {...cell.getCellProps()}>
                  {cell.render("Cell")}
                </TableCell>
              ))}
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default ProjectPage;
