import React from "react";
import {
  Paper, Typography, Box, Stack, IconButton, Button, Dialog, DialogTitle, List, ListItem, ListItemAvatar, ListItemText, Avatar, Chip, SpeedDial, SpeedDialIcon, SpeedDialAction, TextField, InputAdornment
} from '@mui/material'

import ResponsiveAppBar from "../components/AppBar"
import MenuBreadCrumbs from "../components/MenuBreadCrumbs";

function DocumentationPage() {
  return (
    <Paper elevation={0}>
      <ResponsiveAppBar />
      <MenuBreadCrumbs items={['home', 'learnmore']} />
      <Box>
        <Box flex={1} sx={{
          m: 2, mt: 8, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',
        }}>
          <iframe src="./learn-more/" title="Learn more about DOLD" width={1280} height={640} />
        </Box>
      </Box>
    </Paper>
  );
}

export default DocumentationPage;
