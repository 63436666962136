import React from 'react'
import { Backdrop, CircularProgress } from '@mui/material'
import { grey, orange } from '@mui/material/colors'
import {alpha} from '@mui/material/styles'

const CurtainWithProgress = ({ open = false }: { open?: boolean }) => {
  return (
    <Backdrop
      open={open}
      sx={{zIndex: 2000, color: alpha(grey[500], 0.4), backgroundColor: alpha(orange[200], 0.2) }}
    >
      <CircularProgress color={'inherit'} size={100} thickness={4} />
    </Backdrop >
  )
}

export default CurtainWithProgress
