import React from "react";
import { Box, IconButton } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const TextBoxCopy = ({
  text,
  children,
}: {
  text: string;
  children: React.ReactNode;
}) => {
  return (
    <Box
      sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
    >
      <Box
        flex={1}
        sx={{
          p: 1,
          borderWidth: 1,
          borderColor: "lightgrey",
          borderStyle: "solid",
          borderRadius: 2,
        }}
      >
        {children}
      </Box>
      <Box sx={{ ml: 1, p: 1 }}>
        <IconButton onClick={() => navigator.clipboard?.writeText(text)}>
          <ContentCopyIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default TextBoxCopy;
