import {
  Box,
  LinearProgress,
  LinearProgressProps,
  Typography,
} from "@mui/material";

function StorageUsageDisplay(
  props: LinearProgressProps & { usage: string; value: number }
) {
  return (
    <Box sx={{ display: "flex", alignItems: "center", width: 360 }}>
      <Box sx={{ width: "100%", mr: 1, height: 48, position: "relative" }}>
        <LinearProgress
          variant="determinate"
          {...props}
          sx={{
            position: "absolute",
            height: 48,
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
          }}
        />
        <Box
          sx={{
            height: 48,
            position: "absolute",
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
          }}
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography
            variant="body1"
            sx={{ ml: 1, color: "white", fontWeight: "bold" }}
          >
            {props.usage}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${
          Math.round(props.value * 100 + Number.EPSILON) / 100
        }%`}</Typography>
      </Box>
    </Box>
  );
}

export default StorageUsageDisplay;
