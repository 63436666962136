import React from "react";
import { Paper, Typography, Button, Box, Stack, IconButton, Dialog, DialogTitle, List, ListItem, ListItemAvatar, ListItemText, Avatar, TextField, SpeedDial, SpeedDialIcon, SpeedDialAction, Fab, Divider, InputAdornment, ListItemIcon } from '@mui/material'
import {
  Routes,
  Route,
  Link,
  useNavigate,
} from 'react-router-dom';
import { TMedia } from "../resources/types"
import LanguageSelector from "./LanguageSelector";
import TaskUnitSelector from "./TaskUnitSelector"
import moment from 'moment'
import { orange, red, grey } from '@mui/material/colors';
import SaveIcon from '@mui/icons-material/Save';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import ShareIcon from '@mui/icons-material/Share';
import SettingsIcon from '@mui/icons-material/Settings';
import SettingsTwoToneIcon from '@mui/icons-material/SettingsTwoTone';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { canonize } from '../libraries/helper'
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'
import { useSnackbar } from "../services/CustomSnackbarService";
import RenderMedia from "./RenderMedia";
import { TSignedInUserWithSetUser } from '../resources/types'
import { SignedInUserContext } from '../contexts/SignedInUserContext';
import { api } from '../resources/config'
import TypeIcon from './TypeIcon'

const MediaEditor = ({
  disabled = false,
  media,
  createNew = false,
  onChange,
  onSave,
  onCancel,
  onDelete
}: {
  disabled?: boolean;
  media: TMedia;
  createNew?: boolean;
  onChange: (e: TMedia) => void;
  onSave: ({
    code, note, file
  }: {
    code: string;
    note: string;
    file?: File
  }) => void;
  onCancel: () => void;
  onDelete: () => void;
}) => {
  const signedInUserWithSetUser: TSignedInUserWithSetUser | null = React.useContext(SignedInUserContext)
  const [selectedFile, setSelectedFile] = React.useState<File | null>(null)
  const { code, filename, url, mimeTypeMajor, note, uploadedDate, modifiedDate } = media
  const codeInputRef = React.useRef<HTMLInputElement>(null)
  const snackbar = useSnackbar();

  const handleMediaIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (createNew) {
      onChange({
        ...media,
        code: canonize(event.target.value),
      })
    } else {
      console.warn(`handleMediaIdChange(), should not allow to change media ID`)
    }
  }

  const handleMediaIdClear = () => {
    if (createNew) {
      onChange({
        ...media,
        code: '',
      })
    } else {
      console.warn(`handleMediaIdClear(), should not allow to change media ID`)
    }
  }

  const handleMediaIdCheck = async () => {
    console.log(`handleMediaIdCheck()`)
    if (!createNew || !code) {
      return
    }
    try {
      const response = await fetch(api.checkMediaData({ code }), {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Authorization": `Bearer ${signedInUserWithSetUser?.user?.token}`
        }
      })
        .then((res) => res.json())
      if (response.error) {
        throw new Error(response.message || response.error)
      }
      if (response.result) {
        console.log(`media "${code}" already exists`)
        setTimeout(
          () => codeInputRef.current?.focus(),
          100
        )
        snackbar({
          severity: 'error',
          message: `"${code}" already exists. Please use a different ID and consider prepending with the project code.`
        })
      } else {
        console.log(`media "${code}" does not exist`)
        // snackbar({
        //   severity: 'info',
        //   message: `"${code}" can be used as media ID`
        // })
      }
    } catch (err) {
      snackbar({
        severity: 'error',
        message: `Failed to check existence of "${code}": ${(err as Error).message}`
      })
    }
  }

  const handleMediaNoteChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange({
      ...media,
      note: event.target.value
    })
  }

  const handleSelectFile = (file: File) => {
    console.log(`file selected`)
    console.log(file)
    setSelectedFile(file)
  }

  const handleReject = (status: string) => {
    if (status === 'rejected_max_files') {
      snackbar({
        severity: 'warning',
        message: `Only 1 file is accepted. Please remove it before changing to another one.`
      })
    }
  }

  const detailsIncomplete = React.useMemo(
    () => !code || !selectedFile,
    [code, selectedFile]
  )

  const handleSaveMediaChanges = () => {
    if (createNew && detailsIncomplete) {
      return snackbar({
        severity: 'warning',
        message: `Please fill in Media ID and upload a media file`
      })
    }
    onSave({
      code,
      note,
      file: selectedFile ?? undefined
    })
  }

  const handleDeleteMedia = () => {
    onDelete()
  }

  const handleRemoveMediaFile = () => {
    onChange({
      ...media,
      filename: ''
    })
  }

  return (
    <Stack spacing={2}>
      <Box display="flex" flexDirection="row" alignItems="center">
        <TextField
          disabled={disabled}
          inputRef={codeInputRef}
          fullWidth
          label={'Media ID'}
          value={code}
          onChange={handleMediaIdChange}
          onBlur={handleMediaIdCheck}
          placeholder={'e.g. project-abc-pth-intro-1'}
          helperText={createNew ? 'Choose a unique name (in alphanumeric and dash only) for this media item. The name cannot be changed afterwards.' : 'Media ID is read-only once created.'}
          sx={{ flex: 1 }}
          InputProps={{
            readOnly: !createNew || !signedInUserWithSetUser?.accessRight?.canEditMedia,
            endAdornment: createNew && [
              <InputAdornment position="end" key='clear'><IconButton onClick={handleMediaIdClear}><ClearIcon /></IconButton></InputAdornment>,
              <Divider key="divider" orientation="vertical" variant="middle" flexItem />,
              <InputAdornment position="end" key='search'><IconButton color="primary" onClick={handleMediaIdCheck}><SearchIcon /></IconButton></InputAdornment>
            ]
          }}
        />
        {!createNew && <Box display="flex" flexDirection="column" ml={2}>
          <Typography variant="caption" sx={{ fontSize: 'x-small' }}>{`Created: ${moment(uploadedDate).format('YYYY-MM-DD HH:mm:ss')}`}</Typography>
          <Typography variant="caption" sx={{ fontSize: 'x-small' }}>{`Updated: ${moment(modifiedDate).format('YYYY-MM-DD HH:mm:ss')}`}</Typography>
        </Box>}
      </Box>
      <TextField
        disabled={disabled || !signedInUserWithSetUser?.accessRight?.canEditMedia}
        label={'Note'}
        value={note}
        onChange={handleMediaNoteChange}
        placeholder={`e.g. "Putonghua dog story comprehension #3"`}
        helperText={createNew ? `Input a brief note to describe this media item` : 'Update the brief note of this media item'}
      />
      {filename && <Box alignItems={'center'}>
        <RenderMedia size="medium" type={mimeTypeMajor} filename={filename} url={url} playable={true} />
      </Box>}
      {!filename && <MediaFileUploader
        disabled={disabled || !signedInUserWithSetUser?.accessRight?.canEditMedia}
        parameters={{
          code, note
        }}
        onSelectFile={handleSelectFile}
        onReject={handleReject} />}
      {filename && <Box>
        <Button disabled={disabled || !signedInUserWithSetUser?.accessRight?.canEditMedia} onClick={handleRemoveMediaFile} startIcon={<ChangeCircleIcon />}>Change media file</Button>
      </Box>}
      {!disabled && signedInUserWithSetUser?.accessRight?.canEditMedia && <React.Fragment>
        {signedInUserWithSetUser?.accessRight?.canDeleteMedia && !createNew && <Fab color="warning" aria-label="cancel" variant="extended" onClick={handleDeleteMedia} sx={{ position: 'fixed', bottom: 160, right: 32 }}>
          <DeleteIcon sx={{ mr: 1 }} />
          Delete
        </Fab>}
        <Fab color="secondary" aria-label="cancel" variant="extended" onClick={onCancel} sx={{ position: 'fixed', bottom: 96, right: 32 }}>
          <CancelIcon sx={{ mr: 1 }} />
          Cancel
        </Fab>
        <Fab color="success" aria-label="save" variant="extended" onClick={handleSaveMediaChanges} sx={{ position: 'fixed', bottom: 32, right: 32 }}>
          <SaveIcon sx={{ mr: 1 }} />
          Save
        </Fab>

      </React.Fragment>}
    </Stack>
  )
}

const MediaFileUploader = ({ disabled = false, parameters, onSelectFile, onReject }: {
  disabled?: boolean;
  parameters: {
    [key: string]: string
  };
  onSelectFile: (file: File) => void;
  onReject: (status: string) => void;
}) => {
  const handleChangeStatus = ({ meta, file }: { meta: any, file: any }, status: any) => { console.log(status, meta, file) }
  return (
    <React.Fragment>
      <Dropzone
        disabled={disabled}
        maxFiles={1}
        inputContent={(files, extra) =>
          extra.reject ? <span style={{ color: 'white' }}>Image, audio or video files only</span> : 'Drag or select media file'
        }
        inputWithFilesContent={() => null}
        onChangeStatus={(f, status, files) => {
          console.log(status, f.meta, f.file)
          if (status === 'done') {
            console.log(f.file)
            onSelectFile(f.file)
          } else if (status === 'rejected_max_files') {
            onReject(status)
          }
        }}
        accept="image/*,audio/*,video/*"
        styles={{
          dropzone: {
            overflow: 'auto',
          },
          dropzoneReject: {
            backgroundColor: red[300]
          },
          previewImage: {
            maxHeight: 240,
            maxWidth: 240
          }
        }}
      />
      <Box sx={{ pl: 2, color: grey[600] }}>
        <Typography variant="body1">Acceptable formats: </Typography>
        <List sx={{ p: 0 }}>
          <ListItem disablePadding>
            <ListItemIcon><TypeIcon type="video" /></ListItemIcon>
            <ListItemText primary="mp4" />
          </ListItem>
          <ListItem disablePadding>
            <ListItemIcon><TypeIcon type="audio" /></ListItemIcon>
            <ListItemText primary="mp3, ogg, wav" />
          </ListItem>
          <ListItem disablePadding>
            <ListItemIcon><TypeIcon type="image" /></ListItemIcon>
            <ListItemText primary="jpg, png, gif" />
          </ListItem>
        </List>
      </Box>
    </React.Fragment>
  )
}

export default MediaEditor