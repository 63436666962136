import React from 'react'
import { styled } from '@mui/material/styles';
import { Box, Typography, IconButton, Stepper, Step, StepLabel, Button } from '@mui/material'
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import RefreshIcon from '@mui/icons-material/Refresh';
import { StepIconProps } from '@mui/material/StepIcon';
import MicIcon from '@mui/icons-material/Mic';
import Check from '@mui/icons-material/Check';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import { renderTerm } from "../resources/lexicon"

const UsernameHeader = ({ username, language, isLoading = false, allowGoBack = false, allowRestart = false, steps, activeStep, onBackClick, onRestart }: {
  username: string;
  language: string;
  isLoading?: boolean;
  allowGoBack?: boolean;
  allowRestart?: boolean;
  steps?: string[];
  activeStep?: number;
  onBackClick?: () => void;
  onRestart?: () => void;
}) => {
  return (
    <Box sx={{ width: '90%', pt: 1, position: 'fixed', top: 0, display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: 'white', zIndex: 1000 }}>

      {
        <Box sx={{ width: '90%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
          {allowGoBack && <IconButton onClick={onBackClick}>
            <ArrowBackIosIcon fontSize="small" />
          </IconButton>}
          <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            <RecordVoiceOverIcon color="primary" fontSize="small" />
            <Typography color="primary" sx={{ mr: 4, ml: 2 }}>{username}</Typography>
          </Box>
          {
            allowRestart && <Button disabled={isLoading} color="warning" startIcon={<RefreshIcon />} onClick={onRestart}>
              {renderTerm(language, 'startOver')}
            </Button>
          }
        </Box>
      }
      {steps && <Box sx={{ width: '95%' }}>
        <Stepper activeStep={activeStep} alternativeLabel connector={<QontoConnector />}>
          {steps.map((label, index) => (
            <Step key={index}>
              <StepLabel StepIconComponent={QontoStepIcon} />
            </Step>
          ))}
        </Stepper>
      </Box>}
    </Box>
  )
}

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#784af4',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#784af4',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled('div')<{ ownerState: { active?: boolean } }>(
  ({ theme, ownerState }) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && {
      color: '#784af4',
    }),
    '& .QontoStepIcon-completedIcon': {
      color: '#784af4',
      zIndex: 1,
      fontSize: 18,
    },
    '& .QontoStepIcon-circle': {
      width: 8,
      height: 8,
      borderRadius: '50%',
      backgroundColor: 'currentColor',
    },
  }),
);

function QontoStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

export default UsernameHeader