import React from "react";
import { Paper, Typography, Button, Box, Stack, IconButton, Dialog, DialogTitle, List, ListItem, ListItemAvatar, ListItemText, Avatar, TextField, SpeedDial, SpeedDialIcon, SpeedDialAction } from '@mui/material'
import { blue, orange } from '@mui/material/colors';
import AudioFileIcon from '@mui/icons-material/AudioFile';
import { mediaPath } from "../resources/config"

const sizes: {
  [key: string]: {
    width: number;
    height: number;
  }
} = {
  small: {
    width: 120,
    height: 120
  },
  medium: {
    width: 240,
    height: 240
  },
  large: {
    width: 360,
    height: 360
  }
}

const RenderMedia = ({ type, filename, url, thumbnailFilename, thumbnailUrl, size = "medium", playable = false }: { type: string, filename: string, url?: string, thumbnailFilename?: string, thumbnailUrl?: string, size: string, playable?: boolean }) => {
  const { width: maxWidth, height: maxHeight } = sizes[size] ?? sizes.medium
  const style = {
    borderWidth: 1,
    borderRadius: 2,
    borderColor: orange[200],
    borderStyle: 'solid'
  }
  const sizeStyle = {
    maxWidth, maxHeight,
    minHeight: 36
  }
  // return null
  const src = url ? url : `${mediaPath}/${filename}`
  const thumbnailSrc = thumbnailUrl ? thumbnailUrl : (thumbnailFilename ? `${mediaPath}/${thumbnailFilename}` : '')
  return (
    <Box display={'flex'} flexDirection={'column'} alignItems={'center'} sx={style}>
      {type === 'image' && <img src={src} alt={filename} style={sizeStyle} />}
      {type === 'video' && playable && <video controls><source src={src} style={sizeStyle} /></video>}
      {type === 'video' && !playable && !thumbnailSrc && <video controls={false}><source src={src} style={sizeStyle} /></video>}
      {type === 'video' && !playable && thumbnailSrc && <img src={thumbnailSrc} alt={thumbnailFilename ?? ''} style={sizeStyle} />}
      {type === 'audio' && playable && <audio controls={playable}><source src={src} style={sizeStyle} /></audio>}
      {type === 'audio' && !playable && <AudioFileIcon sx={sizeStyle} />}
    </Box>
  )

}

export default RenderMedia