import React from "react";
import { Box } from "@mui/material";
import micCh from "../resources/images/micCh.gif";
import micEn from "../resources/images/micEn.gif";
import useImagePreloader from "../hooks/useImagePreloader";

const micIcons: {
  [language: string]: string;
} = {
  cantonese: micCh,
  putonghua: micCh,
  english: micEn,
};

const RecordingMicIcon = ({
  language = "cantonese",
  isRecording = false,
}: {
  language?: string;
  isRecording?: boolean;
}) => {
  const { imagesPreloaded } = useImagePreloader([micIcons[language] ?? micCh]);
  // React.useEffect(() => {
  //   // preload images
  //   const images = Object.keys(micIcons).map((language) => micIcons[language]);
  //   images.forEach((image) => {
  //     const newImage = new Image();
  //     newImage.src = image;
  //   });
  // }, []);
  return isRecording && imagesPreloaded ? (
    <Box
      component="img"
      sx={{
        height: "10vmin",
      }}
      alt="Recording..."
      src={micIcons[language] ?? micCh}
    />
  ) : null;
};

export default RecordingMicIcon;
