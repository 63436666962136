import React from "react";
import { Paper, Typography, Box, IconButton, Tooltip, Dialog, DialogTitle, List, ListItem, ListItemAvatar, ListItemText, Avatar } from '@mui/material'
import {
  Routes,
  Route,
  Link,
  useNavigate,
} from 'react-router-dom';
import { TExperiment } from "../resources/types"
import moment from 'moment'
import { orange } from '@mui/material/colors';
import EditIcon from '@mui/icons-material/Edit';
import ShareIcon from '@mui/icons-material/Share';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import { renderTerm } from "../resources/lexicon"

const ExperimentCard = ({
  experiment,
  onEdit,
  onCopy,
  onShare,
  onDelete,
  onProjectChange,
  canEditExperiments = false,
  canDeleteExperiments = false
}: {
  experiment: TExperiment,
  onEdit?: () => void,
  onCopy?: () => void,
  onShare?: () => void,
  onDelete?: () => void,
  onProjectChange?: () => void,
  canEditExperiments?: boolean,
  canDeleteExperiments?: boolean,
}) => {
  const { code, language, description, createdDate, modifiedDate } = experiment
  return (
    <Box display="flex" flexDirection="column" justifyContent="space-between" sx={{
      padding: 2,
      borderRadius: 2,
      backgroundColor: orange[100],
      paddingBottom: 0
    }}>
      <Box display="flex" flexDirection="row" flex="1" sx={{ backgroundColor: 'transparent' }}>
        <Box display="flex" flexDirection="column" flex="1">
          <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>{code}</Typography>
          <Typography variant="body1">{description}</Typography>
        </Box>
        <Box display="flex" flexDirection="column">
          <Tooltip title={renderTerm(language, 'language') ? <Typography variant="h5">{renderTerm(language, 'language')}</Typography> : ''}><Typography sx={{ fontSize: 64, userSelect: 'none' }}>{renderTerm(language, 'languageBigLabel')}</Typography></Tooltip>
        </Box>
      </Box>
      {(onEdit || onShare || onDelete) && <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" sx={{ borderTop: 1, borderColor: orange[500], mt: 2 }}>
        <Box>
          {canEditExperiments && onEdit && <Tooltip title="Edit"><IconButton onClick={onEdit}>
            <EditIcon />
          </IconButton></Tooltip>}
          {canEditExperiments && onCopy && <Tooltip title="Duplicate"><IconButton onClick={onCopy}>
            <ContentCopyIcon />
          </IconButton></Tooltip>}
          {onShare && <Tooltip title="Show shareable URL"><IconButton onClick={onShare}>
            <ShareIcon />
          </IconButton></Tooltip>}
          {onProjectChange && <Tooltip title="Change project designation"><IconButton onClick={onProjectChange}>
            <AccountTreeIcon />
          </IconButton></Tooltip>}
        </Box>
        <Tooltip title={`Created: ${moment(createdDate).format('YYYY-MM-DD HH:mm:ss')}`}><Typography variant="caption" sx={{ fontSize: 'x-small' }}>{`Updated: ${moment(modifiedDate).format('YYYY-MM-DD HH:mm:ss')}`}</Typography></Tooltip>
        {canDeleteExperiments && onDelete && <Tooltip title="Delete"><IconButton onClick={onDelete}>
          <DeleteIcon />
        </IconButton></Tooltip>}
      </Box>}
    </Box>
  )
}

export default ExperimentCard