import jsPsychAudioKeyboardResponse from "@jspsych/plugin-audio-keyboard-response";
import jsPsychHtmlAudioResponse from "@jspsych/plugin-html-audio-response";
import jsPsychVideoButtonResponse from "@jspsych/plugin-video-button-response"
import jsPsychVideoKeyboardResponse from "@jspsych/plugin-video-keyboard-response"
import jsPsychHtmlKeyboardResponse from "@jspsych/plugin-html-keyboard-response"
import jsPsychHtmlButtonResponse from "@jspsych/plugin-html-button-response"
import jsPsychSurveyText from "@jspsych/plugin-survey-text"
import jsPsychSurveyMultiChoice from "@jspsych/plugin-survey-multi-choice"
import jsPsychImageButtonResponse from "@jspsych/plugin-image-button-response"
import jsPsychImageKeyboardResponse from "@jspsych/plugin-image-keyboard-response"
import jsPsychAudioButtonResponse from "@jspsych/plugin-audio-button-response"
import { TPlugin } from "../resources/types";

const pluginMap: {
  [pluginName: string]: TPlugin
} = {
  'jsPsychAudioKeyboardResponse': {
    library: jsPsychAudioKeyboardResponse,
    useAudio: true,
    useVideo: false,
    uploadAudio: true,
    uploadText: true,
    advanceProgress: true,
    iPadShowControl: false,
    showRecordingMic: false,
  },
  'jsPsychHtmlAudioResponse': {
    library: jsPsychHtmlAudioResponse,
    useAudio: false,
    useVideo: false,
    uploadAudio: true,
    uploadText: false,
    advanceProgress: true,
    iPadShowControl: true,
    showRecordingMic: true
  },
  'jsPsychHtmlAudioResponseText': {
    library: jsPsychHtmlAudioResponse,
    useAudio: false,
    useVideo: false,
    uploadAudio: true,
    uploadText: false,
    advanceProgress: true,
    iPadShowControl: false,
    showRecordingMic: true
  },
  'jsPsychVideoButtonResponse': {
    library: jsPsychVideoButtonResponse,
    useAudio: false,
    useVideo: true,
    uploadAudio: true,
    uploadText: true,
    advanceProgress: true,
    iPadShowControl: true,
    showRecordingMic: false
  },
  'jsPsychVideoKeyboardResponse': {
    library: jsPsychVideoKeyboardResponse,
    useAudio: false,
    useVideo: true,
    uploadAudio: true,
    uploadText: true,
    advanceProgress: true,
    iPadShowControl: true,
    showRecordingMic: false
  },
  'jsPsychHtmlKeyboardResponse': {
    library: jsPsychHtmlKeyboardResponse,
    useAudio: false,
    useVideo: false,
    uploadAudio: true,
    uploadText: true,
    advanceProgress: true,
    iPadShowControl: true,
    showRecordingMic: false
  },
  'jsPsychHtmlButtonResponse': {
    library: jsPsychHtmlButtonResponse,
    useAudio: false,
    useVideo: false,
    uploadAudio: true,
    uploadText: true,
    advanceProgress: true,
    iPadShowControl: true,
    showRecordingMic: false
  },
  'jsPsychSurveyText': {
    library: jsPsychSurveyText,
    useAudio: false,
    useVideo: false,
    uploadAudio: true,
    uploadText: true,
    advanceProgress: true,
    iPadShowControl: false,
    showRecordingMic: false
  },
  'jsPsychSurveyMultiChoice': {
    library: jsPsychSurveyMultiChoice,
    useAudio: false,
    useVideo: false,
    uploadAudio: true,
    uploadText: true,
    advanceProgress: true,
    iPadShowControl: false,
    showRecordingMic: false
  },
  'jsPsychImageKeyboardResponse': {
    library: jsPsychImageKeyboardResponse,
    useAudio: false,
    useVideo: false,
    uploadAudio: true,
    uploadText: true,
    advanceProgress: true,
    iPadShowControl: false,
    showRecordingMic: false,
  },
  'jsPsychImageButtonResponse': {
    library: jsPsychImageButtonResponse,
    useAudio: false,
    useVideo: false,
    uploadAudio: true,
    uploadText: true,
    advanceProgress: true,
    iPadShowControl: false,
    showRecordingMic: false,
  },
  'jsPsychAudioButtonResponse': {
    library: jsPsychAudioButtonResponse,
    useAudio: true,
    useVideo: false,
    uploadAudio: true,
    uploadText: true,
    advanceProgress: true,
    iPadShowControl: false,
    showRecordingMic: false,
  },
  'jsPsychMediaHtmlButtonResponse': {
    library: jsPsychHtmlButtonResponse,
    useAudio: false,
    useVideo: false,
    uploadAudio: true,
    uploadText: true,
    advanceProgress: true,
    iPadShowControl: false,
    showRecordingMic: false
  },
  'jsPsychMediaHtmlKeyboardResponse': {
    library: jsPsychHtmlKeyboardResponse,
    useAudio: false,
    useVideo: false,
    uploadAudio: true,
    uploadText: true,
    advanceProgress: true,
    iPadShowControl: false,
    showRecordingMic: false
  },
}

export default pluginMap