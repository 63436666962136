import { Select, MenuItem, SelectChangeEvent, FormControl, InputLabel, FormHelperText } from '@mui/material'
import { roles } from "../resources/config"

const RoleSelector = ({ label, managedRoles, role, helperText, onChange }: {
  label?: string;
  managedRoles: string[];
  role: string;
  helperText?: string;
  onChange: (value: string) => void
}) => {
  const handleChange = (event: SelectChangeEvent<string>) => {
    onChange(event.target.value)
  }

  return (
    <FormControl variant="outlined">
      <InputLabel id="role-selector-label">{label ?? ''}</InputLabel>
      <Select
        labelId="role-selector-label"
        value={role}
        onChange={handleChange}
        label={label ?? ''}
      >
        {roles.filter(v => managedRoles.includes(v.id)).map(v => <MenuItem key={v.id} value={v.id} > {v.label} </MenuItem>)}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>)
}

export default RoleSelector