import VideocamIcon from '@mui/icons-material/Videocam';
import ImageIcon from '@mui/icons-material/Image';
import AudiotrackIcon from '@mui/icons-material/Audiotrack';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { blue } from '@mui/material/colors';

const TypeIcon = ({ type }: { type: string }) => {
  if (type === 'image') {
    return (<ImageIcon color={'primary'}/>)
  } else if (type === 'video') {
    return (<VideocamIcon color={'primary'}/>)
  } else if (type === 'audio') {
    return (<AudiotrackIcon color={'primary'}/>)
  } else {
    return <InsertDriveFileIcon color={'primary'}/>
  }
}

export default TypeIcon