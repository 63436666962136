import React from "react";
import { CustomSnackBar, CustomSnackbarOptions } from "./CustomSnackbar";

const CustomSnackbarServiceContext = React.createContext<
  (options: CustomSnackbarOptions) => Promise<void>
>(Promise.reject);

export const useSnackbar = () =>
  React.useContext(CustomSnackbarServiceContext);

export const CustomSnackbarServiceProvider = ({ children }: { children: React.ReactNode }) => {
  const [
    snackbarState,
    setSnackbarState
  ] = React.useState<CustomSnackbarOptions | null>(null);
  const [open, setOpen] = React.useState<boolean>(false);

  const awaitingPromiseRef = React.useRef<{
    resolve: () => void;
    reject: () => void;
  }>();

  const openSnackbar = React.useCallback(
    (options: CustomSnackbarOptions) => {
      setSnackbarState(options);
      setOpen(true)
      return new Promise<void>((resolve, reject) => {
        awaitingPromiseRef.current = { resolve, reject };
      });
    }, [setSnackbarState, setOpen]
  )

  const handleClose = () => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve();
    }
    setOpen(false)
  };

  const handleExited = () => {
    setSnackbarState(null)
  }

  return (
    <>
      <CustomSnackbarServiceContext.Provider
        value={openSnackbar}
        children={children}
      />
      <CustomSnackBar
        open={open}
        onClose={handleClose}
        onExited={handleExited}
        {...snackbarState}
      />
    </>
  );
};
