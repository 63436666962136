import React from "react";
import { Typography, Box } from '@mui/material'
import { blue } from '@mui/material/colors'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const defaultJsonLineCount = 5

const SimpleJsonViewer = ({
  json
}: {
  json: string;
}) => {
  const [expanded, setExpanded] = React.useState(false)
  const [lines, setLines] = React.useState<string[]>([])

  React.useEffect(
    () => {
      setLines((json ?? '').split(/\n+/))
    },
    [json]
  )

  if (lines.length <= defaultJsonLineCount) {
    return (
      <pre style={{ whiteSpace: 'break-spaces' }}>
        {lines.join('\n')}
      </pre>
    )
  }

  return (
    <Box>
      {expanded ?
        <pre style={{ whiteSpace: 'break-spaces' }}>
          {lines.join('\n')}
          <Typography variant="body2" onClick={() => setExpanded(false)} sx={{ mt: 1, fontSize: 'x-small', cursor: 'pointer', color: blue[500] }}>
            <ExpandLessIcon sx={{ verticalAlign: 'middle', fontSize: 'x-small' }} />
            {' ...less... '}
            <ExpandLessIcon sx={{ verticalAlign: 'middle', fontSize: 'x-small' }} />
          </Typography>
        </pre> :
        <pre style={{ whiteSpace: 'break-spaces' }}>
          {lines.slice(0, defaultJsonLineCount).join('\n')}
          <Typography variant="body2" onClick={() => setExpanded(true)} sx={{ mt: 1, fontSize: 'x-small', cursor: 'pointer', color: blue[500] }}>
            <ExpandMoreIcon sx={{ verticalAlign: 'middle', fontSize: 'x-small' }} />
            {' ...more... '}
            <ExpandMoreIcon sx={{ verticalAlign: 'middle', fontSize: 'x-small' }} />
          </Typography>
        </pre>
      }
    </Box>
  )
}

export default SimpleJsonViewer